<template>
  <div class="background">
    <img src="../assets/origami.jpg" />

    <div class="form">
      <!-- <div class="note">
        <i>Origami</i> will be released in
        <b>{{ this.daysTillReleaseText }}</b> on <b>Friday March 17 2023</b>. By
        continuing you will end up at the Spotify playlist where the song will
        be added to once it is available. <b>Follow</b> me on Spotify to receive
        the song <i>Origami</i> in your <b>Release Radar</b>.
      </div> -->

      <form @submit.prevent="listen()">
        <label
          >Want to receive the sheet music for free?<br />
          <input
            id="emailField"
            type="email"
            v-model="model.email"
            placeholder="Leave your email (optional)"
        /></label>
        <div class="errorMsg" v-if="errorMsg">{{ errorMsg }}</div>
        <input type="submit" :disabled="processing" v-model="submitValue" />
      </form>
      <div class="disclaimer">
        By submitting the form you agree to the
        <a href="#" v-on:click="showTerms()">terms and conditions</a>
        <div class="disclaimer" id="disclaimer">
          <ul>
            <li>
              You agree I register your click which allows me to better target
              other potentional listeners.
            </li>
            <li>
              You agree that I store your email address (if provided) for
              (infrequent) future emails, for example when I release a new EP or
              album.
            </li>
            <li>
              The sheet music won't be sent directly to your email address. You
              will receive a coupon code that can be used in my webshop, which
              gives a 100% discount when applied to the 'Origami' sheet music.
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { v4 as uuidv4 } from "uuid";

export default {
  name: "SongNav",
  data() {
    return {
      now: Math.floor(Date.now() / 1000),
      processing: false,
      errorMsg: "",
      model: {
        email: "",
        fbp: null,
        song: "Origami",
        fbc: null,
        event_id: null,
      },
    };
  },
  created() {
    var self = this;
    setInterval(function () {
      self.now = Math.floor(Date.now() / 500);
    }, 500);
  },
  computed: {
    displayNote() {
      return this.daysTillRelease > 0;
    },
    daysTillReleaseText() {
      const diffDays = this.daysTillRelease;
      const s = diffDays == 1 ? "" : "s";
      return diffDays + " day" + s;
    },
    daysTillRelease() {
      const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
      const secondDate = new Date(2023, 2, 15);

      return Math.ceil(Math.abs((secondDate - Date.now()) / oneDay));
    },
    submitValue() {
      if (this.processing) {
        const mod_value = 6;
        const nr_of_dots = this.now % mod_value;
        const nr_of_spaces = mod_value - nr_of_dots;
        return (
          "Processing" + " " + ".".repeat(nr_of_dots) + " ".repeat(nr_of_spaces)
        );
      } else {
        return "Listen on Spotify";
      }
    },
    listenUrl() {
      return process.env.VUE_APP_LISTEN_URL;
    },
    fbc() {
      const params = new Proxy(new URLSearchParams(window.location.search), {
        get: (searchParams, prop) => searchParams.get(prop),
      });
      let res = this.getCookie("_fbc");
      if (res == null && params.fbclid != null) {
        res = "fb.1." + Date.now() + "." + params.fbclid;
      }
      return res;
    },
  },
  methods: {
    showTerms() {
      const el = document.getElementById("disclaimer");
      if (el.style.display == "block") {
        el.style.display = "none";
      } else {
        el.style.display = "block";
      }
    },
    getCookie(name) {
      let value = `; ${document.cookie}`;
      let parts = value.split(`; ${name}=`);
      if (parts.length === 2) return parts.pop().split(";").shift();
      else return null;
    },
    listen() {
      this.processing = true;
      document.activeElement.blur();

      this.model.fbp = this.getCookie("_fbp");
      this.model.fbc = this.fbc;
      this.model.event_id = uuidv4();
      this.model.source_url = window.location.href.split("?")[0];

      axios
        .post(this.listenUrl, JSON.stringify(this.model), {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then(() => {
          this.$analytics.openSpotify({ song: "Origami" }, this.model.event_id);
          this.processing = false;
          this.model.email = "";
          setTimeout(() => {
            window.location.href =
              "https://open.spotify.com/playlist/3eGKBCskh43cJOfAGlfCzh?si=df84d13aa266491d";
          }, 300);
        })
        .catch((error) => {
          let input = document.getElementById("emailField");
          input.classList.add("error");

          if (error.response.data == "invalid_email")
            this.errorMsg = "The provided email address is not valid.";
          else this.errorMsg = error.response.data;

          setTimeout(() => {
            input.classList.remove("error");
            this.errorMsg = "";
            input.focus();
            this.processing = false;
          }, 2000);
        });
    },
  },
};
</script>

<style scoped>
div {
  font-family: Helvetica, Arial;
  font-size: 16px;
}
input {
  display: block;
  width: 100%;
  padding: 10px;
  margin-top: 10px;
  font-size: 20px;
}

input[type="submit"] {
  color: white;
  /* margin-top: 10px; */
  background-color: #1db954;
}
input[type="submit"]:disabled {
  color: white;
  /* margin-top: 10px; */
  background-color: #425448;
}
label {
  /* margin-left: 10px; */
  width: 100%;
  font-size: 16px;
  font-weight: bold;
}
.error {
  outline: 4px solid darkred;
}
.errorMsg {
  color: darkred;
  margin-top: 10px;
}
.disclaimer {
  font-size: 10px;
  color: grey;
  padding-top: 10px;
}
.note {
  border: 3px solid rgb(186, 89, 10);
  padding: 10px;
  background-color: #ffc187;
  margin-bottom: 20px;
  font-size: 11px;
}
#disclaimer {
  display: none;
}
a {
  color: #1db954;
}
img {
  display: unset;
  height: 100%;
  width: 100%;
  /* width: 300px; */
}
.form {
  padding: 15px;
  width: 100%;
  margin: auto;
}
@media only screen and (min-width: 900px) {
  .background {
    display: flex;
    align-items: center;
  }
  img {
    height: 100vh;
    width: 100vh;
  }
  .form {
    width: min(500px, 100%);
    margin: 20px;
  }
}
</style>