<template>
  <h1>{{ t("shop.title") }}</h1>
  <!-- <h1 class="progress">. . .</h1> -->
  <!-- <router-link :to="{ name: 'cart', params: { lang: this.$i18n.locale } }"> -->
  <a @click="submitShop()">
    <h1 class="cart">{{ this.totalProductQuantity }} &#x1f6D2;</h1>
  </a>
  <!-- </router-link> -->

  <div class="messages" id="messages">
    <div></div>
    <form id="shopForm" @submit.prevent="goToCart()">
      <div
        v-for="[id, item] in Object.entries(shopItems)"
        v-bind:key="id"
        class="item"
      >
        <div class="shopItem">
          <div class="productImage images">
            <img
              @click="showImg(item.images)"
              :key="getImgUrl(item.image)"
              :src="getImgUrl(item.image)"
            />
          </div>
          <div class="productTitle">
            <p>
              <span>{{ item.language[this.$i18n.locale].title }}</span
              ><br />
              <span v-if="item.allow_price_edit">
                <label>€ </label>
                <input
                  class="price"
                  :value="getPrice(id)"
                  :id="'price_' + id"
                  @input="setPrice($event, id)"
                  type="number"
                  inputmode="numeric"
                  :required="isRequired(id)"
                  pattern="[0-9]*"
                  :min="getMinimum(id)"
                />
                &nbsp;(min. €{{ item.min_price }})
              </span>
              <span v-if="!item.allow_price_edit">€{{ item.price }}</span
              ><br />
              <a @click="showMore">{{ t("shop.showDetails") }}</a>
            </p>
          </div>
          <div class="productAction">
            <div><a @click="addToCart(id, +1)">&plus;</a></div>
            <div v-if="isInCart(id)">
              <a @click="addToCart(id, -1)">&minus;</a>
            </div>
          </div>
        </div>
        <div class="productDesc hide">
          <p v-html="item.language[this.$i18n.locale].description"></p>
          <ol>
            <li v-for="track in item.tracklist" v-bind:key="track">
              {{ track }}
            </li>
          </ol>
          <ul>
            <li
              v-for="prop in item.language[this.$i18n.locale].properties"
              v-bind:key="prop"
            >
              {{ prop }}
            </li>
          </ul>
        </div>
      </div>
      <div class="actions">
        <!-- <router-link :to="{ name: 'cart', params: { lang: this.$i18n.locale } }"> -->
        <input id="shopSubmit" type="submit" :value="t('cart.showcart')" />
        <!-- </router-link> -->
      </div>
    </form>
  </div>
</template>

<script>
import { useI18n } from "vue-i18n";
import shopItems from "../data/shop.json";
export default {
  name: "Shop",
  setup() {
    return useI18n();
  },
  data() {
    return {
      options: {
        toolbar: false,
      },
      shopItems: shopItems.catalog,
      cart: {},
    };
  },
  computed: {
    totalProductQuantity: function () {
      return this.$store.getters.totalProductQuantity;
    },
  },
  methods: {
    showImg(images) {
      this.$viewerApi({
        options: this.options,
        images: images.map((img) => {
          return this.getImgUrl(img);
        }),
      });
    },
    submitShop() {
      document.getElementById("shopSubmit").click();
    },
    goToCart() {
      this.$router.push({
        name: "cart",
        params: { lang: this.$i18n.locale },
        hash: "#shop",
      });
    },
    onlyDigits(event) {
      return event.charCode == 8 || event.charCode == 0 || event.charCode == 13
        ? null
        : event.charCode >= 48 && event.charCode <= 57;
    },
    getImgUrl(pic) {
      const a = {
        "abracadabra-sheet.jpg": require("../assets/abracadabra-sheet.jpg"),
        "voor-roos-sheet.jpg": require("../assets/voor-roos-sheet.jpg"),
        "voor-roos-1.jpg": require("../assets/voor-roos-1.jpg"),
        "voor-roos-2.jpg": require("../assets/voor-roos-2.jpg"),
        "voor-roos-3.jpg": require("../assets/voor-roos-3.jpg"),
        "voor-roos-4.jpg": require("../assets/voor-roos-4.jpg"),
        "origami-sheet-1.jpg": require("../assets/origami-sheet-1.jpg"),
        "origami-sheet-2.jpg": require("../assets/origami-sheet-2.jpg"),
        "origami-sheet-3.jpg": require("../assets/origami-sheet-3.jpg"),
        "origami-sheet-4.jpg": require("../assets/origami-sheet-4.jpg"),
        "voor-lize-sheet-1.jpg": require("../assets/voor-lize-sheet-1.jpg"),
        "voor-lize-sheet-2.jpg": require("../assets/voor-lize-sheet-2.jpg"),
        "voor-lize-sheet-3.jpg": require("../assets/voor-lize-sheet-3.jpg"),
        "voor-lize-sheet-4.jpg": require("../assets/voor-lize-sheet-4.jpg"),
        "ik-ben-de-ochtend-sheet-1.jpg": require("../assets/ik-ben-de-ochtend-sheet-1.jpg"),
        "ik-ben-de-ochtend-sheet-2.jpg": require("../assets/ik-ben-de-ochtend-sheet-2.jpg"),
        "haverklap-sheet-1.jpg": require("../assets/haverklap-sheet-1.jpg"),
        "haverklap-sheet-2.jpg": require("../assets/haverklap-sheet-2.jpg"),
        "cover-square.jpg": require("../assets/cover-square.jpg"),
        "abracadabra-sheet-1.jpg": require("../assets/abracadabra-sheet-1.jpg"),
        "abracadabra-sheet-2.jpg": require("../assets/abracadabra-sheet-2.jpg"),
        "abracadabra-sheet-3.jpg": require("../assets/abracadabra-sheet-3.jpg"),
        "abracadabra-sheet-4.jpg": require("../assets/abracadabra-sheet-4.jpg"),
        "backcover-square.jpg": require("../assets/backcover-square.jpg"),
        "cover-square-150.jpg": require("../assets/cover-square-150.jpg"),
        "voorjonne-thumbnail.jpg": require("../assets/voorjonne-thumbnail.jpg"),
        "voorroos-thumbnail.jpg": require("../assets/voorroos-thumbnail.jpg"),
      };

      return a[pic];
    },
    isInCart(id) {
      return (
        this.$store.state.cart[id] && this.$store.state.cart[id].quantity > 0
      );
    },
    showMore(event) {
      const d =
        event.target.parentNode.parentNode.parentNode.parentNode.lastChild;
      if (d.classList.contains("hide")) {
        event.target.text = this.t("shop.hideDetails");
        d.classList.remove("hide");
      } else {
        event.target.text = this.t("shop.showDetails");
        d.classList.add("hide");
      }
    },
    addToCart(id, addition) {
      let event = { id, addition };
      this.$store.commit("addToCart", event);
      this.$analytics.addToCart(event);
    },
    isRequired(id) {
      return (
        this.$store.state.cart[id] && this.$store.state.cart[id].quantity > 0
      );
    },
    getPrice(id) {
      return this.$store.state.cart[id] && this.$store.state.cart[id].price;
    },
    getMinimum(id) {
      return this.$store.state.cart[id] &&
        this.$store.state.cart[id].quantity > 0
        ? this.shopItems[id].min_price
        : 0;
    },
    setPrice(input, id) {
      let event = { id, value: input.target.value };
      window.fbq("track", "setPrice", event);
      this.$store.commit("setPrice", event);
    },
  },
};
</script>

<style scoped>
.shopItem {
  display: flex;
  float: left;
  width: 100%;
  /* background-color: black; */
  padding-left: 25px;
  padding-right: 25px;
  /* width: 100%; */
}

h1 {
  float: right;
}

.progress {
  float: unset;
  display: inline-block;
  text-align: center;
  border: 1px solid black;
  left: 50%;
}

.cart {
  float: left;
  padding-top: 16px;
}

input {
  padding: 8px;
}

input[type="submit"] {
  background-color: rgba(16, 65, 115, 0.95);
  width: 100%;
  color: white;
  border: 0px;
  cursor: pointer;
  padding: 10px;
  -webkit-appearance: none;
}

.ignore {
  all: unset;
  background-color: black;
}

.hide {
  display: none;
}

.item {
  padding-bottom: 20px;
}

.shopItem p {
  padding-left: unset;
  margin-top: unset;
  padding-bottom: unset;
  /* line-height: unset; */
}

.shopItem .productImage {
  width: 20%;
  /* background-color: red; */
}

.shopItem .productImage img {
  display: unset;
  cursor: pointer;
}

.shopItem .productTitle {
  width: 75%;
  padding-left: 20px;
  /* padding-right: 20px; */
  /* background-color: blue; */
}

a h1 {
  cursor: pointer;
}

.productTitle a {
  font-style: italic;
  font-size: 12px;
  cursor: pointer;
}

.productDesc {
  width: 95%;
  clear: both;
  /* display: none; */
}

.price {
  width: 5em;
}

.productDesc ol {
  list-style-position: inside;
  list-style-type: decimal-leading-zero;
  padding-left: 25px;
  line-height: 1.5;
}

.productDesc p {
  padding-bottom: unset;
}

li {
  font-family: "STIX Two Math", "Lato", "Merriweather", "Truculenta", "Lekton",
    "Puritan", "Neutral", "Helvetica Neue";
  font-size: 18px;
  letter-spacing: 1.5px;
  line-height: 1.8;
}

.productDesc ul {
  list-style-position: inside;
  /* list-style-type: decimal-leading-zero; */
  padding-left: 0;
}

.shopItem .productAction {
  width: 5%;
  color: white;
  /* background-color: purple; */
}

.productAction div {
  cursor: pointer;
  /* font-size: 20px; */
  /* display: inline-block; */
  color: white;
  border-radius: 50%;
  border-color: black;
  /* padding-top: 16px;
  padding-left: 6px; */
  height: 20px;
  width: 20px;
  text-align: center;
  font-weight: 800;
  background-color: rgba(16, 65, 115, 0.95);
  /* line-height: 0; */
  position: relative;
  margin-top: 5px;
  margin-bottom: 10px;
}

.actions {
  padding-left: 25px;
  padding-right: 25px;
  margin-bottom: 30px;
  margin-top: 30px;
}

.actions div {
  background-color: rgba(16, 65, 115, 0.95);
  color: white;
  width: 100%;
  margin-top: 20px;
  display: inline-block;
  text-align: center;
  padding: 10px;
  /* margin-left: 2%; */
  cursor: pointer;
  /* font-size: 12px; */
}

@media only screen and (min-width: 900px) {
  .shopItem {
    padding-left: min(5vw, 8vh);
    padding-right: min(5vw, 8vh);
    margin-bottom: 20px;
  }

  input {
    font-size: min(1.2vw, 2.2vh);
  }

  .actions {
    padding-left: 5vw;
    padding-right: 5vw;
    font-size: min(1.4vw, 2.7vh);
  }

  .productAction div {
    height: 30px;
    width: 30px;
    font-size: 24px;
  }

  .productTitle a {
    font-style: italic;
    font-size: 1vw;
    cursor: pointer;
  }

  .productDesc ol {
    list-style-position: inside;
    list-style-type: decimal-leading-zero;
    padding-left: 5vw;
    line-height: 1.8;
  }
}
</style>