<template>
  <meta
    http-equiv="Refresh"
    content="0; url='https://drive.google.com/file/d/1xJKkzaL0SRUfc8hmc7C_nemLq7jBtBaq/view?usp=sharing'"
  />
</template>

<script>
export default {
  name: "Epk",
};
</script>