<template>
  <meta
    http-equiv="Refresh"
    content="0; url='https://betaalverzoek.knab.nl/0o4R2a0k1cHdLnl'"
  />
</template>

<script>
export default {
  name: "PaymentRequest",
};
</script>