<template>
  <div class="background">
    <div class="nav">
      <h1>
        <a href="../"><img class="title" src="../assets/abra-title.png" /></a>
      </h1>
      <!-- <h3>&nbsp;&nbsp;<a href="../">bas beelen</a></h3>   -->
      <!-- <div class="logo_container">
        <img class="gluren" src="../assets/gluren.png" /><br />
      </div>
      <div class="items">
        <a
          href="https://betaalverzoek.knab.nl/XE866gjIIYc68ep"
          target="_blank"
          class="item"
          >Bijdrage / support</a
        >
      </div>
      <br /><br /><br /> -->
      Stream this album on:<br /><br />
      <div class="items">
        <a
          v-bind:href="stream.url"
          target="_blank"
          class="item"
          v-bind:key="stream.platform"
          v-for="stream in streams"
          >{{ stream.platform }}</a
        >
      </div>
      <br />
      Buy this album on: <br /><br />
      <a
        v-bind:href="store.url"
        target="_blank"
        class="item"
        v-bind:key="store.platform"
        v-for="store in stores"
        >{{ store.platform }}</a
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "SongNav",
  data() {
    return {
      streams: [
        {
          platform: "Spotify",
          url: "https://open.spotify.com/album/5Rb2cNsbtC7XeOb7ZBqjiP?si=8BMflHtmSlWt100yU5Z5Kg",
        },
        {
          platform: "Apple Music",
          url: "https://music.apple.com/album/abracadabra/1631549810?app=music&at=1000lxtM&ls=1",
        },
        {
          platform: "Amazon Music",
          url: "https://music.amazon.com/albums/B0BL8GKXH6?ref=dm_sh_dfa4-2537-8b36-9f63-78d3a",
        },
        {
          platform: "Deezer",
          url: "https://www.deezer.com/en/album/372325017",
        },
        { platform: "Tidal", url: "https://listen.tidal.com/album/257965429" },
        {
          platform: "YouTube Music",
          url: "https://music.youtube.com/browse/MPREb_Zn0kZOXFLh6",
        },
      ],
      stores: [
        {
          platform: "Bandcamp",
          url: "https://basbeelen.bandcamp.com/album/abracadabra",
        },
        {
          platform: "iTunes",
          url: "https://music.apple.com/nl/album/abracadabra/1631549810?app=itunes",
        },
        {
          platform: "Amazon",
          url: "https://www.amazon.com/dp/B0B527MLN4",
        },
      ],
    };
  },
};
</script>

<style scoped>
.text {
  padding: 40px;
}
.title {
  width: 250px;
}
a {
  text-decoration: none;
  /* display: block; */
  /* padding: 10px; */
  /* float: left; */
  /* border: 2px solid red; */
}
.cultuurhoek {
  width: 300px;
  /* margin-left: 80px; */
}
.container {
  overflow: hidden;
  height: 100vh;
}
.background {
  overflow: scroll;
  background-attachment: fixed;
  background-image: url("../assets/cover-blurred-2.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
}
.nav {
  font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue",
    Helvetica, Arial, "Lucida Grande", sans-serif;
  text-align: center;
  color: white;
  top: 40px;
}
img {
  display: unset;
  /* width: 300px; */
}
.item {
  display: inline-block;
  opacity: 0.8;
  font-weight: bold;
  margin: 10px auto;
  padding: 10px;
  border-radius: 25px;
  width: 200px;
  left: 50%;
  float: center;
  background-color: white;
  color: black;
  margin-left: 7px;
}
.nav h1 {
  font-size: 70px;
  letter-spacing: -1px;
  font-family: "OneLove";
  font-weight: normal;
}
.items {
  margin-left: 15%;
  margin-right: 15%;
}
.logo_container {
  margin: 0px auto;
  width: 200px;
  overflow: hidden;
  /* margin-left: 150px; */
}
.nav h3 {
  font-family: "STIX Two Math";
  letter-spacing: 7px;
  margin-top: -65px;
  font-size: 14px;
  font-weight: normal;
  margin-bottom: 50px;

  background-image: linear-gradient(
    to right,
    #462523 0,
    #cb9b51 22%,
    #decc74 45%,
    #f6f2c0 50%,
    #decc74 55%,
    #cb9b51 78%,
    #462523 100%
  );
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}
</style>