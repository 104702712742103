<template>
    <div>
    <h2>Privacy Policy</h2>

    I care about your privacy and try my best to keep the amount of data collected by me and third parties to a minimum. Please read on to learn more.

    <h3>First party cookies</h3>
    This website does <b><u>not</u></b> set any first-party cookies. In other words, I am not setting any cookies or collect any Personally Identifiable Information (PII) myself.

    <h3>Third party cookies</h3>
    However, I do make use of some third party applications for different purposes, I'll dive deeper into them below.

    <div>
    <h4>Analytics</h4>
    This website uses Google Analytics 4th generation (GA4) to track website usage, e.g. to get an idea about the amount of visitors I get per day, the visit duration and from which country/city people reach this website. GA4 no longer stores complete IP addresses, so no Personally Identifiable Information is being captured.

    <h4>YouTube videos</h4>
    This website may contain embedded YouTube videos. I am using a widget that by default is privacy friendly and loads the video's from the <b>youtube-nocookie.com</b> domain. In other words, the YouTube video's you encounter here do <b><u>not</u></b> set a third party tracking cookie to collect your data.

    <h4>Spotify widget</h4>
    This website may contain an embedded Spotify widget to expose my music. This widget <i>does</i> set a third party cookie which I am not able to prevent as far as I can tell right now. I am referring you to the widget's privacy policy for more details: <a href="https://developer.spotify.com/documentation/widgets/terms/">https://developer.spotify.com/documentation/widgets/terms/</a>
    </div>
    <br />I hope this privacy statement was informative and satisfactory. If you have any complaints, please <a href="mailto:mail@basbeelen.com">contact me</a>
    </div><br /><br />
</template>

<style scoped>
h2 {
    font-family: Helvetica Neue;
    color: #000000;
    background-image: #000000;
    -webkit-text-fill-color: unset;
    -webkit-background-clip: unset;
}
div {
    width: 500px;
    font-family: Helvetica neue;
    margin-left: 50px;
}
</style>