<template>
  <NavBar />
  <div class="holder">
    <Album />
    <News />
    <About />
    <Gigs />
    <Media />
    <div class="row">
      <div class="col-6" id="shop">
        <router-view id="shop"></router-view>
        <div class="shop-footer">
          {{ t("shop.shipping") }}<br /><br />
          <a href="https://www.mollie.com" target="_blank"><img class="mollie"
              src="../assets/payments-by-mollie-light.png" /></a><br />
          <img class="paymentLogo" src="../assets/ideal.svg" />
          <img class="paymentLogo" src="../assets/paypal.svg" />
          <img class="paymentLogo" src="../assets/creditcard.svg" />
          <img class="paymentLogo" src="../assets/applepay.svg" />
          <img class="paymentLogo" src="../assets/giropay.svg" />
          <!-- <img class="paymentLogo" src="../assets/amex.svg" /> -->
          <img class="paymentLogo" src="../assets/bancontact.svg" />
        </div>
      </div>
      <div class="col-6 shop right-side">
        <img src="../assets/shop3.jpg" />
      </div>
    </div>

    <Contact />
  </div>
</template>

<script>
import { useI18n } from "vue-i18n";
import { defineComponent } from "vue";
import NavBar from "../components/NavBar.vue";
import Album from "../components/Album.vue";
import News from "../components/News.vue";
import About from "../components/About.vue";
import Gigs from "../components/Gigs.vue";
import Media from "../components/Media.vue";
import Contact from "../components/Contact.vue";

import "vue-lite-youtube-embed/style.css";

export default defineComponent({
  name: "App",
  props: {
    lang: {
      type: String,
      required: true,
      default: "en",
    },
  },
  data() { },
  setup() {
    return useI18n();
  },
  components: {
    NavBar,
    Album,
    News,
    About,
    Gigs,
    Media,
    Contact,
    // Shop,
  },
  mounted() {
    document.title = this.t("title");
  },
  created() {
    this.$i18n.locale = this.lang; //this.$route.params.lang;
  },
  watch: {
    $route() {
      this.$i18n.locale = this.$route.params.lang || this.lang;
      document.title = this.t("title");

      // if (this.$route.hash) {
      //   console.log("foo");
      //   document.getElementById("shop").scrollIntoView({
      //     behavior: "smooth",
      //     block: "start",
      //     inline: "nearest",
      //   });
      // }
    },
  },
});
</script>

<style>
body {
  background: repeating-linear-gradient(45deg,
      #eaeaea,
      #eaeaea 10px,
      #eeeeee 10px,
      #eeeeee 20px);
}

h1 {
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 20px;
  background-image: linear-gradient(to right,
      #462523 0,
      #cb9b51 22%,
      #decc74 45%,
      #f6f2c0 50%,
      #decc74 55%,
      #cb9b51 78%,
      #462523 100%);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}

/* used for creating href-style links that call javascript functions */
.plink {
  cursor: pointer;
  text-decoration: underline;
}

p {
  /* margin-bottom: 25px; */
  padding-left: 25px;
  padding-right: 25px;
  padding-bottom: 15px;
  font-family: "STIX Two Math", "Lato", "Merriweather", "Truculenta", "Lekton",
    "Puritan", "Neutral", "Helvetica Neue";
  font-size: 18px;
  letter-spacing: 1.5px;
  line-height: 1.8;
}

p span {
  color: #a2800f;
  font-size: 18px;
  /* font-weight: 1200;/] */
  /* color: #ffc2a1; */
}

p a {
  color: darkblue;
}

img {
  width: 100%;
  max-width: 1000px;
  /* height: 100vh; */
}

[class*="col-"] {
  width: 100%;
  float: left;
}

[class*="menu-"] {
  float: left;
}

.row {
  background-color: white;
}

.sidenote {
  border-radius: 10px;
  margin-left: 25px;
  margin-right: 25px;
  padding-left: 30px;
  letter-spacing: 1.5px;
  line-height: 1.8;
  padding-right: 30px;
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: #fae8da;
  margin-bottom: 40px;
  /* display: flex; */
  /* align-items: center; */
}

.sidenote-container {
  float: left;
  width: 35%;
  margin-left: 25px;
  margin-right: 25px;
}

.sidenote-container .sidenote {
  margin-left: 0px;
  margin-right: 0px;
}

.half {
  width: 100%;
  /* float: left; */
  padding-left: 20px;
  padding-right: 20px;
  font-family: Arial;
  font-size: 14px;
  margin-left: 0px;
  margin-right: 0px;
  margin-bottom: 20px;
  /* display: flex; */
}

.half:last-of-type {
  /* margin-left: 0px; */
}

.half a {
  text-decoration: none;
}

.sidenote img {
  display: unset;
  width: 60px;
  float: left;
  margin-right: 20px;
  vertical-align: middle;
}

.sidenote .error {
  font-style: italic;
  color: rgb(172, 12, 12);
}

.sidenote a {
  color: darkblue;
  font-size: 15px;
}

.sidenote input {
  box-sizing: border-box;
  padding: 8px;
  margin-top: 5px;
  margin-bottom: 5px;
}

.success {
  border: 2px solid green;
}

.sidenote input[type="text"] {
  width: 75%;
}

.sidenote input[type="text"]:focus {
  box-shadow: none;
}

.row::after {
  content: "";
  clear: both;
  display: table;
}

.shop-footer {
  text-align: center;
  padding-top: 20px;
  margin-bottom: 30px;
}

.mollie {
  width: 150px;
}

.paymentLogo {
  width: 32px;
  margin: 2px;
}

@media only screen and (max-width: 900px) {
  .row:nth-child(even) {
    display: flex;
    flex-direction: column-reverse;
  }
}

@media only screen and (min-width: 900px) {
  .holder {
    width: min(200vh, 100%);
    margin: auto;
  }

  .half {
    width: 100%;
    float: left;
    padding-left: 20px;
    padding-right: 20px;
    font-family: Arial;
    /* margin-bottom: min(min(2.5vw, 4vh), 40px); */
    /* display: flex; */
  }

  .sidenote a {
    color: darkblue;
    font-size: min(1.3vw, 2.1vh);
  }

  .sidenote .small {
    letter-spacing: 1px;
  }

  .sidenote .small a {
    font-size: min(1.1vw, 2.4vh);
  }

  .sidenote {
    margin-left: min(5vw, 8vh);
    margin-right: min(5vw, 8vh);
  }

  .sidenote-container {
    float: left;
    width: 35%;
    margin-left: min(5vw, 8vh);
    margin-right: min(min(3vw, 8vh), 40px);
  }

  .left-side {
    background-attachment: fixed;
    background-repeat: no-repeat;
    /* background-position: 0px; */
    background-size: 100vh;
  }

  .right-side {
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-position: 50vw;
    /*calc(100% + 10px + ((50vw - 100%) / 2));*/
    background-size: 100vh;
  }

  img {
    display: none;
  }

  h1 {
    padding-left: min(5vw, 8vh);
    padding-right: min(5vw, 8vh);
    font-size: min(2.5vw, 5vh);
  }

  .col-6:nth-child(odd) h1:first-of-type {
    text-align: right;
  }

  .col-6:nth-child(even) h1:first-of-type {
    text-align: left;
  }

  p {
    padding-left: min(5vw, 8vh);
    padding-right: min(5vw, 8vh);
    font-size: min(1.4vw, 2.7vh);
    text-align: justify;
  }

  p span {
    font-size: min(1.5vw, 2.75vh);
  }

  [class*="col-6"] {
    width: 50%;
  }

  [class*="col-5"] {
    width: 40%;
  }

  [class*="col-7"] {
    width: 60%;
  }

  .row {
    display: flex;
    width: min(200vh, 100%);
    float: left;
  }

  .shop {
    background-image: url("../assets/shop3.jpg");
  }

  .shop-footer img {
    display: unset;
  }

  .mollie {
    width: min(15vw, 20vh);
  }

  .paymentLogo {
    width: min(3vw, 4vh);
    margin: 5px;
  }
}

@media only screen and (min-width: 1400px) {}
</style>
