import cryptojs from 'crypto-js'
import shopItems from "../data/shop.json";

const coupons = shopItems.coupons;
const salt = cryptojs.enc.Base64.parse("vJslhxLPgGAQ3pm1Ef9nXA==")

export default class Coupons {
    static validate(input) {
        let userHash = cryptojs.PBKDF2(input, salt, {
            keySize: 512 / 32,
            iterations: 1000,
        }).toString(cryptojs.enc.Base64);

        return Object.entries(coupons).filter(([key,]) => {
            return (key == userHash)
        })
    }
}
