<template>
  <div class="row" id="album">
    <div class="col-6 left-side cover" @click="toAlbum()">
      <img src="../assets/cover-square.jpg" />
    </div>

    <div class="col-6">
      <h1>{{ t("album.title") }}</h1>

      <div class="sidenote-container">
        <div class="sidenote half">
          <a href="#shop"
            ><img src="../assets/cover-square-150.jpg" />
            {{ t("album.getacopy") }}</a
          >
        </div>
        <div class="sidenote half">
          <a href="#shop"
            ><img src="../assets/origami-sheet-1.jpg" />'Origami'
            {{ t("album.sheetmusic") }}</a
          >
        </div>
      </div>
      <p v-html="t('album.sections.section1')"></p>
      <p v-html="t('album.sections.section2')"></p>
      <p v-html="t('album.sections.section3')"></p>
      <p v-html="t('album.sections.section4')"></p>
    </div>
  </div>
</template>

<script>
import { useI18n } from "vue-i18n";
export default {
  name: "Album",
  setup() {
    return useI18n();
  },
  methods: {
    toAlbum() {
      window.open("/abracadabra", "_blank").focus();
    },
  },
};
</script>

<style scoped>
@media only screen and (max-height: 50vw) {
  .cover {
    background-size: 100vh;
    background-position: calc((100vw - 200vh) / 2);
    /* background-position: 200px; */
  }
}
@media only screen and (min-width: 900px) {
  .cover {
    /* background-image: url("../assets/cover-1200x1300.jpg"); */
    background-image: url("../assets/cover-square.jpg");
    /* background-position: -15px; */
    cursor: pointer;
  }
}
@media only screen and (min-width: 1400px) {
  .cover {
    /* background-image: url("../assets/cover-1200x1300.jpg"); */
    background-image: url("../assets/cover-square.jpg");
    /* background-position: 100px; */
    cursor: pointer;
  }
}
</style>