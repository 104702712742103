import { createStore } from 'vuex';
import Coupon from "./model/Coupon.js";
import shop from "./data/shop.json";

const FREE_ShIPPING_THRESHOLD = 15;
// const salt = VueCryptojs.$CryptoJS.enc.Base64.parse("vJslhxLPgGAQ3pm1Ef9nXA==");

export const store = createStore({
    state() {
        return {
            cart: {},
            coupon: "",
        }
    },
    mutations: {
        initialiseStore(state) {
            // Check if the ID exists
            if (localStorage.getItem('store')) {
                // Replace the state object with the stored item
                this.replaceState(
                    Object.assign(state, JSON.parse(localStorage.getItem('store')))
                );
            }
        },
        addToCart(state, payload) {
            if (state.cart[payload.id]) {
                state.cart[payload.id].quantity += payload.addition;
                if (state.cart[payload.id].quantity == 0) {
                    delete state.cart[payload.id];
                }
            } else {
                state.cart[payload.id] = {
                    quantity: payload.addition
                }
            }
        },
        addCoupon(state, payload) {
            state.coupon = payload;
        },
        setPrice(state, payload) {
            if (state.cart[payload.id]) {
                state.cart[payload.id].price = payload.value;
            }
            else {
                state.cart[payload.id] = {
                    price: payload.value,
                    quantity: 0
                }
            }
        },
        clearCart(state, payload) {
            state.cart = {};
            state.coupon = "";
        }
    },
    getters: {
        free_shipping_treshold() {
            return FREE_ShIPPING_THRESHOLD;
        },
        cartItems(state) {
            return Object.entries(state.cart).map(([key, value]) => {
                let s = { ...shop.catalog[key], quantity: value.quantity, id: key };
                if (s.allow_price_edit) {
                    s.price = value.price;
                }
                return s;
            }).filter(item => {
                return item.quantity > 0
            });
        },
        coupons(state) {
            const coupon = Coupon.validate(state.coupon);
            if (!coupon.length) return [];

            const [, d] = coupon[0];
            let details = JSON.parse(JSON.stringify(d))

            const matchingCouponItems = details.applies_to.filter(x => Object.keys(state.cart).includes(x));
            const couponValue = matchingCouponItems.length > 0 ? details.value : 0;

            details.value = couponValue;
            details.id = state.coupon;

            let s = [details]

            return s;
        },
        requires_shipping(state, getters) {
            return Object.values(getters.cartItems).reduce((total, item) => {
                return total || item.requires_shipping;
            }, false);
        },
        requires_sheetmusic(state, getters) {
            return Object.values(getters.cartItems).reduce((total, item) => {
                return total || item.is_sheet_music;
            }, false);
        },
        requires_contact(state, getters) {
            return true;
        },
        requires_extra(state, getters) {
            return true;
        },
        cartIsEmpty(state, getters) {
            return Object.values(getters.cartItems).reduce((total, item) => {
                return total + item.quantity;
            }, 0) == 0;
            // return Object.keys(state.cart).length == 0;
        },
        checkoutFields(state, getters) {
            var self = this;
            return function (source) {
                return Object.entries(source).filter(([key, field]) => {
                    return getters["requires_" + key]
                });
            }
        },
        subtotal(state, getters) {
            return Object.values(getters.cartItems).reduce(
                (total, { quantity, price }) => {
                    return total + quantity * price;
                },
                0
            ) - getters.couponValues
        },
        couponValues(state, getters) {
            return Object.values(getters.coupons).reduce(
                (total, { value }) => total + value
                , 0);
        },
        validUserPrices(state, getters) {
            return Object.values(getters.cartItems).reduce(
                (isValid, { price, min_price }) => {
                    return isValid && (!min_price || (price >= min_price))
                }, true
            );
        },
        shippingCosts(state, getters) {
            if (getters.subtotal >= FREE_ShIPPING_THRESHOLD || !getters.requires_shipping)
                return 0;
            else return 2;
        },
        totalCosts(state, getters) {
            return getters.subtotal + getters.shippingCosts;
        },
        totalProductQuantity(state) {
            return Object.values(state.cart).reduce(
                (total, { quantity }) => {
                    return total + quantity;
                },
                0
            );
        }
    }
});