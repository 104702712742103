<template>
  <div>
    <h2>Algemene voorwaarden Bb Major Productions</h2>

    <h3>Definities</h3>
    <ol>
      <li>
        Klant: degene met wie Bb Major Productions een overeenkomst is
        aangegaan.
      </li>

      <li>Partijen: Bb Major Productions en klant samen.</li>

      <li>
        Consument: een klant die tevens een individu is en die als privépersoon
        handelt.
      </li>
    </ol>

    <h3>Toepasselijkheid algemene voorwaarden</h3>
    <ol>
      <li>
        Deze voorwaarden zijn van toepassing op alle offertes, aanbiedingen,
        werkzaamheden, bestellingen, overeenkomsten en leveringen van diensten
        of producten door of namens Bb Major Productions.
      </li>
      <li>
        Partijen kunnen alleen afwijken van deze voorwaarden als zij dat
        uitdrukkelijk en schriftelijk zijn overeengekomen.
      </li>
      <li>
        Partijen sluiten de toepasselijkheid van aanvullende en/of afwijkende
        algemene voorwaarden van de klant of van derden uitdrukkelijk uit.
      </li>
    </ol>

    <h3>Prijzen</h3>
    <ol>
      <li>
        Alle prijzen die Bb Major Productions hanteert zijn in euro’s, zijn
        inclusief btw en exclusief eventuele overige kosten zoals
        administratiekosten, heffingen en reis-, verzend- of transportkosten,
        tenzij uitdrukkelijk anders vermeld of anders overeengekomen.
      </li>
      <li>
        Alle prijzen die Bb Major Productions hanteert voor zijn producten, op
        zijn website of die anderszins kenbaar zijn gemaakt, kan Bb Major
        Productions te allen tijde wijzigen.
      </li>
      <li>
        Verhogingen van de kostprijzen van producten of onderdelen daarvan, die
        Bb Major Productions niet kon voorzien ten tijde van het doen van de
        aanbieding c.q. het tot stand komen van de overeenkomst, kunnen
        aanleiding geven tot prijsverhogingen.
      </li>
      <li>
        De consument heeft het recht om een overeenkomst te ontbinden als gevolg
        van een prijsverhoging zoals bedoeld in lid 3, tenzij de verhoging het
        gevolg is van een wettelijke regeling.
      </li>
    </ol>

    <h3>Betalingen en betalingstermijn</h3>
    <ol>
      <li>
        Bb Major Productions mag bij het aangaan van de overeenkomst een
        aanbetaling tot 50% van het overeengekomen bedrag verlangen.
      </li>
      <li>
        De klant dient betalingen achteraf binnen 7 dagen na levering te hebben
        voldaan.
      </li>
      <li>
        Betalingstermijnen worden beschouwd als fatale betalingstermijnen. Dat
        betekent dat indien de klant het overeengekomen bedrag niet uiterlijk op
        de laatste dag van de betalingstermijn heeft voldaan, hij van rechtswege
        in verzuim en in gebreke is, zonder dat Bb Major Productions de klant
        een aanmaning hoeft te sturen c.q. in gebreke hoeft te stellen.
      </li>
      <li>
        Bb Major Productions behoudt zich het recht voor om een levering
        afhankelijk te stellen van onmiddellijke betaling dan wel een
        zekerheidstelling te eisen voor het totale bedrag van de diensten of
        producten.
      </li>
    </ol>

    <h3>Gevolgen niet tijdig betalen</h3>
    <ol>
      <li>
        Betaalt de klant niet binnen de overeengekomen termijn, dan is Bb Major
        Productions gerechtigd de wettelijke rente van 2% per maand voor
        niet-handelstransacties in rekening te brengen vanaf de dag dat de klant
        in verzuim is, waarbij een gedeelte van een maand voor een hele maand
        wordt gerekend.
      </li>
      <li>
        Wanneer de klant in verzuim is, is hij bovendien buitengerechtelijke
        incassokosten en eventuele schadevergoeding verschuldigd aan Bb Major
        Productions.
      </li>
      <li>
        De incassokosten worden berekend aan de hand van het Besluit vergoeding
        voor buitengerechtelijke incassokosten.
      </li>
      <li>
        Wanneer de klant niet tijdig betaalt, mag Bb Major Productions zijn
        verplichtingen opschorten totdat de klant aan zijn betalingsverplichting
        heeft voldaan.
      </li>
      <li>
        In geval van liquidatie, faillissement, beslag of surseance van betaling
        aan de zijde van de klant, zijn de vorderingen van Bb Major Productions
        op de klant onmiddellijk opeisbaar.
      </li>
      <li>
        Weigert de klant zijn medewerking aan de uitvoering van de overeenkomst
        door Bb Major Productions, dan is hij nog steeds verplicht de
        afgesproken prijs aan Bb Major Productions te betalen.
      </li>
    </ol>

    <h3>Recht van reclame</h3>
    <ol>
      <li>
        Zodra de klant in verzuim is, is Bb Major Productions gerechtigd het
        recht van reclame in te roepen ten aanzien van de onbetaalde aan de
        klant geleverde producten.
      </li>
      <li>
        Bb Major Productions roept het recht van reclame in door middel van een
        schriftelijke of elektronische mededeling.
      </li>
      <li>
        Zodra de klant op de hoogte is gesteld van het ingeroepen recht van
        reclame, dient de klant de producten waar dit recht betrekking op heeft,
        onmiddellijk te retourneren aan Bb Major Productions, tenzij partijen
        hierover andere afspraken maken.
      </li>
      <li>
        De kosten voor het terughalen of -brengen van de producten komen voor
        rekening van de klant.
      </li>
    </ol>

    <h3>Herroepingsrecht</h3>
    <ol>
      <li>
        Een consument kan een online aankoop gedurende een bedenktijd van 14
        dagen zonder opgave van reden ontbinden op voorwaarde dat:
        <ul>
          <li>het product niet is gebruikt</li>
          <li>
            het geen product is dat snel kan bederven, zoals voedsel of bloemen
          </li>
          <li>
            het geen product is dat speciaal voor de consument op maat is
            gemaakt of aangepast
          </li>
          <li>
            de verzegeling nog intact is, indien het gaat om gegevensdragers met
            digitale inhoud (dvd’s, cd’s, etc.)
          </li>
          <li>
            het product of dienst geen logies, reis, restaurantbedrijf, vervoer,
            cateringopdracht of vorm van vrijetijdsbesteding betreft
          </li>
          <li>het product geen los tijdschrift of losse krant is</li>
          <li>de consument niet heeft afgezien van zijn herroepingsrecht</li>
        </ul>
      </li>
      <li>
        De bedenktijd van 14 dagen zoals in lid 1 genoemd, vangt aan:
        <ul>
          <li>
            op de dag nadat de consument het laatste product of onderdeel heeft
            ontvangen van 1 bestelling
          </li>
          <li>
            zodra de consument bevestigd heeft dat hij digitale inhoud via
            internet gaat afnemen
          </li>
        </ul>
      </li>

      <li>indien gewenst door een email te sturen naar mail@basbeelen.com</li>
      <li>
        De consument is verplicht om het product binnen 14 dagen na het kenbaar
        maken van zijn herroepingsrecht te retourneren aan Bb Major Productions,
        bij gebreke waarvan zijn herroepingsrecht komt te vervallen.
      </li>
    </ol>

    <h3>Vergoeding van bezorgkosten</h3>
    <ol>
      <li>
        Indien de consument tijdig van zijn herroepingsrecht gebruik heeft
        gemaakt en als gevolg daarvan tijdig de volledige bestelling aan Bb
        Major Productions heeft geretourneerd, dan zal Bb Major Productions
        eventuele door de consument betaalde verzendkosten binnen 14 dagen na
        ontvangst van de tijdig en volledig geretourneerde bestelling aan de
        consument terugbetalen.
      </li>
      <li>
        De kosten voor bezorgen komen slechts voor rekening van Bb Major
        Productions voor zover de volledige bestelling wordt geretourneerd.
      </li>
    </ol>

    <h3>Vergoeding retourkosten</h3>
    <ol>
      <li>
        Indien de consument een beroep doet op zijn herroepingsrecht en de
        volledige bestelling tijdig retourneert, dan komen de kosten voor het
        retourneren van de volledige bestelling voor rekening van de consument.
      </li>
    </ol>

    <h3>Opschortingsrecht</h3>
    <ol>
      <li>
        Tenzij de klant een consument is, doet de klant afstand van het recht om
        de nakoming van enige uit deze overeenkomst voortvloeiende verbintenis
        op te schorten.
      </li>
    </ol>

    <h3>Retentierecht</h3>
    <ol>
      <li>
        Bb Major Productions kan een beroep doen op zijn retentierecht en in dat
        geval producten van de klant onder zich houden, totdat de klant alle nog
        openstaande rekeningen ten aanzien van Bb Major Productions heeft
        voldaan, tenzij de klant voor die kosten voldoende zekerheid heeft
        gesteld.
      </li>
      <li>
        De retentierecht geldt eveneens op grond van eerdere overeenkomsten
        waaruit de klant nog betalingen verschuldigd is aan Bb Major
        Productions.
      </li>
      <li>
        Bb Major Productions is nooit aansprakelijk voor eventuele schade die de
        klant mogelijkerwijs lijdt als gevolg van het gebruikmaken van zijn
        retentierecht.
      </li>
    </ol>

    <h3>Verrekening</h3>
    <ol>
      <li>
        Tenzij de klant een consument is, doet de klant afstand van zijn recht
        om een schuld aan Bb Major Productions te verrekenen met een vordering
        op Bb Major Productions.
      </li>
    </ol>

    <h3>Eigendomsvoorbehoud</h3>
    <ol>
      <li>
        Bb Major Productions blijft eigenaar van alle geleverde producten totdat
        de klant volledig heeft voldaan aan al zijn betalingsverplichtingen ten
        aanzien van Bb Major Productions op grond van wat voor met Bb Major
        Productions gesloten overeenkomst dan ook, met inbegrip van vorderingen
        inzake het tekortschieten in de nakoming.
      </li>
      <li>
        Tot die tijd kan Bb Major Productions zich beroepen op zijn
        eigendomsvoorbehoud en de zaken terugnemen.
      </li>
      <li>
        Voordat het eigendom is overgegaan op de klant, mag de klant de
        producten niet verpanden, verkopen, vervreemden of anderszins bezwaren.
      </li>
      <li>
        Indien Bb Major Productions een beroep doet op zijn eigendomsvoorbehoud,
        geldt de overeenkomst als ontbonden en heeft Bb Major Productions het
        recht om schadevergoeding, gederfde winst en rente te vorderen.
      </li>
    </ol>

    <h3>Levering</h3>
    <ol>
      <li>Levering vindt plaats zolang de voorraad strekt.</li>
      <li>
        Levering vindt plaats digitaal en/of via post/pakket verzending, tenzij
        partijen anders zijn overeengekomen.
      </li>
      <li>
        Levering van online bestelde producten vindt plaats op het door de klant
        aangegeven adres.
      </li>
      <li>
        Indien de overeengekomen bedragen niet of niet op tijd worden voldaan,
        heeft Bb Major Productions het recht om zijn verplichtingen op te
        schorten totdat het overeengekomen deel alsnog is voldaan.
      </li>
      <li>
        Bij te late betaling is er sprake van schuldeisersverzuim, met als
        gevolg dat de klant een verlate levering niet aan Bb Major Productions
        kan tegenwerpen.
      </li>
    </ol>

    <h3>Levertijd</h3>
    <ol>
      <li>
        De door Bb Major Productions opgegeven levertijden zijn indicatief en
        geven de klant bij overschrijding daarvan geen recht op ontbinding of
        schadevergoeding, tenzij partijen uitdrukkelijk en schriftelijk anders
        zijn overeengekomen.
      </li>
      <li>
        De levertijd vangt aan op het moment dat de klant het (elektronische)
        bestelproces volledig heeft afgerond en daarvan een (elektronische)
        bevestiging heeft gekregen van Bb Major Productions.
      </li>
      <li>
        Overschrijding van de opgegeven levertijd geeft de klant geen recht op
        schadevergoeding en evenmin het recht om de overeenkomst te ontbinden,
        tenzij Bb Major Productions niet binnen 14 dagen na daartoe schriftelijk
        te zijn aangemaand kan leveren of partijen hierover iets anders hebben
        afgesproken.
      </li>
    </ol>

    <h3>Feitelijke levering</h3>
    <ol>
      <li>
        De klant dient ervoor zorg te dragen dat de feitelijke levering van de
        door hem bestelde producten tijdig kan plaatsvinden.
      </li>
    </ol>

    <h3>Transportkosten</h3>
    <ol>
      <li>
        Transportkosten zijn voor rekening van de klant, tenzij partijen
        hierover iets anders hebben afgesproken.
      </li>
    </ol>

    <h3>Verpakking en verzending</h3>
    <ol>
      <li>
        Indien de verpakking van een geleverd product geopend of beschadigd is,
        dan dient de klant, alvorens het product in ontvangst te nemen, hiervan
        door de expediteur c.q. bezorger een aantekening op te laten maken, bij
        gebreke waarvan Bb Major Productions niet aansprakelijk kan worden
        gehouden voor eventuele schade.
      </li>
      <li>
        Indien de klant zelf voor transport van een product zorgdraagt, dient
        hij eventuele zichtbare beschadigingen aan producten of de verpakking
        voorafgaand aan het vervoer te melden aan Bb Major Productions, bij
        gebreke waarvan Bb Major Productions niet aansprakelijk kan worden
        gehouden voor eventuele schade.
      </li>
    </ol>

    <h3>Bewaring</h3>
    <ol>
      <li>
        Indien de klant bestelde producten pas later afneemt dan de
        overeengekomen leveringsdatum, is het risico van een eventueel
        kwaliteitsverlies geheel voor de klant.
      </li>
      <li>
        Eventuele extra kosten als gevolg van voortijdige dan wel verlate afname
        van producten komen geheel voor rekening van de klant.
      </li>
    </ol>

    <h3>Garantie</h3>
    <ol>
      <li>
        De garantie met betrekking tot producten is uitsluitend van toepassing
        op defecten, veroorzaakt door ondeugdelijk(e) fabricage, constructie of
        materiaal.
      </li>
      <li>
        De garantie geldt niet in het geval van normale slijtage en van schade
        ontstaan als gevolg van ongevallen, aangebrachte wijzigingen aan het
        product, nalatigheid of ondeskundig gebruik door de klant, alsmede
        wanneer de oorzaak van het defect niet duidelijk kan worden vastgesteld.
      </li>
      <li>
        Het risico van verlies, beschadiging of diefstal van de producten die
        het voorwerp zijn van een overeenkomst tussen partijen, gaat over op de
        klant op het moment waarop deze juridisch en/of feitelijk worden
        geleverd, althans in de macht van de klant komen of van een derde die
        het product ten behoeve van de klant in ontvangst neemt.
      </li>
    </ol>

    <h3>Vrijwaring</h3>
    <ol>
      <li>
        De klant vrijwaart Bb Major Productions tegen alle aanspraken van derden
        die verband houden met de door Bb Major Productions geleverde producten
        en/of diensten.
      </li>
    </ol>

    <h3>Klachten</h3>
    <ol>
      <li>
        De klant dient een door Bb Major Productions geleverd product of
        verleende dienst zo spoedig mogelijk te onderzoeken op eventuele
        tekortkomingen.
      </li>
      <li>
        Beantwoordt een geleverd product of verleende dienst niet aan hetgeen de
        klant redelijkerwijs van de overeenkomst mocht verwachten, dan dient de
        klant Bb Major Productions daarvan zo spoedig mogelijk, doch in ieder
        geval binnen 1 maand na constatering van de tekortkomingen, op de hoogte
        te stellen.
      </li>
      <li>
        Consumenten dienen Bb Major Productions uiterlijk binnen 2 maanden na
        constatering van de tekortkomingen hiervan op de hoogte te stellen.
      </li>
      <li>
        De klant geeft daarbij een zo gedetailleerd mogelijke omschrijving van
        de tekort­koming, zodat Bb Major Productions in staat is hierop adequaat
        te reageren.
      </li>
      <li>
        De klant dient aan te tonen dat de klacht betrekking heeft op een
        overeenkomst tussen partijen.
      </li>
      <li>
        Indien een klacht betrekking heeft op lopende werkzaamheden, kan dit er
        in ieder geval niet toe leiden dat Bb Major Productions gehouden kan
        worden om andere werkzaamheden te verrichten dan zijn overeengekomen.
      </li>
    </ol>

    <h3>Ingebrekestelling</h3>
    <ol>
      <li>
        De klant dient ingebrekestellingen schriftelijk kenbaar te maken aan Bb
        Major Productions.
      </li>
      <li>
        Het is de verantwoordelijkheid van de klant dat een ingebrekestelling Bb
        Major Productions ook daadwerkelijk (tijdig) bereikt.
      </li>
    </ol>

    <h3>Hoofdelijke aansprakelijkheid klant</h3>
    <ol>
      <li>
        Als Bb Major Productions een overeenkomst aangaat met meerdere klanten,
        is ieder van hen hoofdelijk aansprakelijk voor de volledige bedragen die
        zij op grond van die overeenkomst aan Bb Major Productions verschuldigd
        zijn.
      </li>
    </ol>

    <h3>Aansprakelijkheid Bb Major Productions</h3>
    <ol>
      <li>
        Bb Major Productions is uitsluitend aansprakelijk voor enige schade die
        de klant lijdt indien en voor zover die schade is veroorzaakt door opzet
        of bewuste roekeloosheid.
      </li>
      <li>
        Indien Bb Major Productions aansprakelijk is voor enige schade, is het
        slechts aansprakelijk voor directe schade die voortvloeit uit of verband
        houdt met de uitvoering van een overeenkomst.
      </li>
      <li>
        Bb Major Productions is nooit aansprakelijk voor indirecte schade, zoals
        gevolgschade, gederfde winst, gemiste besparingen of schade aan derden.
      </li>
      <li>
        Indien Bb Major Productions aansprakelijk is, is deze aansprakelijkheid
        beperkt tot het bedrag dat door een gesloten
        (beroeps)aansprakelijkheidsverzekering wordt uitbetaald en bij gebreke
        van (volledige) uitkering door een verzekeringsmaatschappij van het
        schadebedrag is de aansprakelijkheid beperkt tot het (gedeelte van het)
        factuurbedrag waarop de aansprakelijkheid betrekking heeft.
      </li>
      <li>
        Alle afbeeldingen, foto’s, kleuren, tekeningen, omschrijvingen op de
        website of in een catalogus zijn slechts indicatief en gelden slechts
        bij benadering en kunnen geen aanleiding zijn tot schadevergoeding en/of
        (gedeeltelijke) ontbinding van de overeenkomst en/of opschorting van
        enige verplichting.
      </li>
    </ol>

    <h3>Vervaltermijn</h3>
    <ol>
      <li>
        Elk recht van de klant op schadevergoeding van Bb Major Productions
        vervalt in elk geval 12 maanden na de gebeurtenis waaruit de
        aansprakelijkheid direct of indirect voortvloeit. Hiermee wordt niet
        uitgesloten het bepaalde in artikel 6:89 van het Burgerlijk Wetboek.
      </li>
    </ol>

    <h3>Recht op ontbinding</h3>
    <ol>
      <li>
        De klant heeft het recht de overeenkomst te ontbinden wanneer Bb Major
        Productions toerekenbaar tekortschiet in de nakoming van zijn
        verplichtingen, tenzij deze tekortkoming, gezien haar bijzondere aard of
        geringe betekenis, de ontbinding niet rechtvaardigt.
      </li>
      <li>
        Is de nakoming van de verplichtingen door Bb Major Productions niet
        blijvend of tijdelijk onmogelijk, dan kan ontbinding pas plaatsvinden
        nadat Bb Major Productions in verzuim is.
      </li>
      <li>
        Bb Major Productions heeft het recht de overeenkomst met de klant te
        ontbinden, indien de klant zijn verplichtingen uit de overeenkomst niet
        volledig of niet tijdig nakomt, dan wel indien Bb Major Productions
        kennis heeft genomen van omstandigheden die hem goede grond geven om te
        vrezen dat de klant zijn verplichtingen niet behoorlijk zal kunnen
        nakomen.
      </li>
    </ol>

    <h3>Overmacht</h3>
    <ol>
      <li>
        In aanvulling op het bepaalde in artikel 6:75 Burgerlijk Wetboek geldt
        dat een tekortkoming van Bb Major Productions in de nakoming van enige
        verplichting ten aanzien van de klant niet aan Bb Major Productions kan
        worden toegerekend in een van de wil van Bb Major Productions
        onafhankelijke situatie, waardoor de nakoming van zijn verplichtingen
        ten aanzien van de klant geheel of gedeeltelijk wordt verhinderd of
        waardoor de nakoming van zijn verplichtingen in redelijk­heid niet van
        Bb Major Productions kan worden verlangd.
      </li>
      <li>
        Tot de in lid 1 genoemde overmachtsituatie worden ook - doch niet
        uitsluitend - gerekend: noodtoestand (zoals burgeroorlog, opstand,
        rellen, natuurrampen, etc.); wanprestaties en overmacht van
        toeleveranciers, bezorgers of andere derden; onverwachte stroom-,
        elektriciteits- internet-, computer- en telecomstoringen;
        computer­virussen, stakingen, overheidsmaatregelen, onvoorziene
        vervoersproblemen, slechte weersomstandigheden en werkonderbrekingen.
      </li>
      <li>
        Indien zich een overmachtsituatie voordoet waardoor Bb Major Productions
        1 of meer verplichtingen naar de klant niet kan nakomen, dan worden die
        verplichtingen opgeschort totdat Bb Major Productions er weer aan kan
        voldoen.
      </li>
      <li>
        Vanaf het moment dat een overmachtsituatie ten minste 30 kalenderdagen
        heeft geduurd, mogen beide partijen de overeenkomst schriftelijk geheel
        of gedeeltelijk ontbinden.
      </li>
      <li>
        Bb Major Productions is in een overmachtsituatie geen enkele
        (schade)vergoeding verschuldigd, ook niet als het als gevolg van de
        overmachttoestand enig voordeel geniet.
      </li>
    </ol>

    <h3>Wijziging van de overeenkomst</h3>
    <ol>
      <li>
        Indien na het afsluiten van de overeenkomst voor de uitvoering ervan het
        nodig blijkt om de inhoud ervan te wijzigen of aan te vullen, passen
        partijen tijdig en in onderling overleg de overeenkomst
        dienovereenkomstig aan. Voorgaand lid is niet van toepassing bij
        producten die zijn afgenomen in een fysieke winkel.
      </li>
    </ol>

    <h3>Wijziging algemene voorwaarden</h3>
    <ol>
      <li>
        Bb Major Productions is gerechtigd deze algemene voorwaarden te wijzigen
        of aan te vullen.
      </li>
      <li>
        Wijzigingen van ondergeschikt belang kunnen te allen tijde worden
        doorgevoerd.
      </li>
      <li>
        Grote inhoudelijke wijzigingen zal Bb Major Productions zoveel mogelijk
        vooraf met de klant bespreken.
      </li>
      <li>
        Consumenten zijn gerechtigd bij een wezenlijke wijziging van de algemene
        voorwaarden de overeenkomst op te zeggen.
      </li>
    </ol>

    <h3>Overgang van rechten</h3>
    <ol>
      <li>
        Rechten van de klant uit een overeenkomst tussen partijen kunnen niet
        aan derden worden overgedragen zonder de voorafgaande schriftelijke
        instemming van Bb Major Productions.
      </li>
      <li>
        Deze bepaling geldt als een beding met goederenrechtelijke werking zoals
        bedoeld in artikel 3:83, tweede lid, Burgerlijk Wetboek.
      </li>
    </ol>

    <h3>Gevolgen nietigheid of vernietigbaarheid</h3>
    <ol>
      <li>
        Wanneer één of meerdere bepalingen van deze algemene voorwaarden nietig
        of vernietigbaar blijken, dan tast dit de overige bepalingen van deze
        voorwaarden niet aan.
      </li>
      <li>
        Een bepaling die nietig of vernietigbaar is, wordt in dat geval
        vervangen door een bepaling die het dichtst in de buurt komt van wat Bb
        Major Productions bij het opstellen van de voorwaarden op dat punt voor
        ogen had.
      </li>
    </ol>

    <h3>Toepasselijk recht en bevoegde rechter</h3>
    <ol>
      <li>
        Op iedere overeenkomst tussen partijen is uitsluitend het Nederlands
        recht van toepassing.
      </li>
      <li>
        De Nederlandse rechter in het arrondissement waar Bb Major Productions
        is gevestigd / praktijk houdt / kantoor houdt is exclusief bevoegd om
        kennis te nemen van eventuele geschillen tussen partijen, tenzij de wet
        dwingend anders voorschrijft.
      </li>
    </ol>

    Opgesteld op 01 september 2022.
  </div>
</template>

<style scoped>
h2 {
  font-family: Helvetica Neue;
  color: #000000;
  background-image: #000000;
  -webkit-text-fill-color: unset;
  -webkit-background-clip: unset;
}
div {
  width: 700px;
  font-family: Helvetica neue;
  margin-left: 50px;
}
</style>
