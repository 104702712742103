import { createWebHistory, createRouter } from 'vue-router';
import Home from './components/Home.vue';
import SongNav from './components/SongNav.vue';
import Privacy from './components/Privacy.vue';
import Shop from "./components/Shop.vue";
import Cart from "./components/Cart.vue";
import Origami from "./components/Origami.vue";
import Checkout from "./components/Checkout.vue";
import ThankYou from "./components/ThankYou.vue";
import PaymentRequest from "./components/PaymentRequest.vue";
import Epk from "./components/Epk.vue";
import Voorwaarden from "./components/Voorwaarden.vue";
import Returns from "./components/Returns.vue";

function setLanguage(route) {
    const defaultLang = navigator.languages.includes("nl") ? "nl" : "en";
    // if (!route.params.lang) {
    //     router.push(defaultLang);
    // }
    return { lang: route.params.lang || defaultLang };
}

const routes = [
    {
        path: '/abracadabra',
        component: SongNav
    },
    {
        path: '/betaalverzoek',
        component: PaymentRequest,
        name: 'betaalverzoek'
    },
    {
        path: '/origami',
        component: Origami,
        name: 'origami'
    },
    {
        path: '/epk',
        component: Epk,
        name: 'epk'
    },
    {
        path: '/privacy',
        component: Privacy
    },
    {
        path: '/voorwaarden',
        component: Voorwaarden
    },
    {
        path: '/returns',
        component: Returns
    },
    // {
    //     path: '/:lang(en|nl)/thankyou/:orderId',
    //     name: 'thankyou',
    //     component: ThankYou
    // },
    {
        path: '/:lang(en|nl)',
        component: Home,
        props: setLanguage,
        children: [
            {
                path: '',
                component: Shop,
                name: 'language'
            },
            {
                path: 'shop',
                component: Shop,
                name: 'shop'
            },
            {
                path: 'cart',
                component: Cart,
                name: 'cart'
            },
            {
                path: 'thankyou/:orderId',
                component: ThankYou,
                name: 'thankyou'
            },
            {
                path: 'checkout',
                component: Checkout,
                name: 'checkout'
            }
        ]
    },
    {
        path: '/',
        component: Home,
        props: setLanguage,
        children: [
            {
                path: '',
                component: Shop
            }
        ]
    },
    {
        path: '/:pathMatch(.*)',
        redirect: '/'
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior(to) {
        if (to.hash) {
            return {
                el: to.hash,
            }
        }
    }
});

export default router;