<template>
  <router-view />
</template>

<script>
export default {
  name: "App",
  methods: {
    extractParams(path) {
      let params = path.split("?")[1];
      if (!params) return {};
      let items = params.split("&");
      let result = {};
      items.array.forEach((element) => {
        let values = element.split("=");
        result[values[0]] = values[1];
      });
      return result;
    },
  },
  mounted() {
    let path = localStorage.getItem("path");
    if (path) {
      const params = this.extractParams(path);
      localStorage.removeItem("path");
      let sections = path.split("/");
      if (sections.length == 1) {
        this.$router.push({ path: path, query: params });
      } else if (sections.length == 2) {
        this.$router.push({
          name: sections[1],
          params: { lang: sections[0] },
          query: params,
        });
      } else if (sections.length == 3 && sections[1] == "thankyou") {
        this.$router.push({
          name: sections[1],
          params: { lang: sections[0], orderId: sections[2] },
          query: params,
          hash: "#shop",
        });
      }
    }

    this.$store.commit("initialiseStore");

    // const nextTick = this.$nextTick;
    // this.$router.afterEach((to) => {
    //   nextTick(() => {
    //     document.title = to.meta.title;
    //   });
    // });
  },
};
</script>

<style>
@font-face {
  font-family: onelove;
  src: url("./assets/onelove.ttf");
}
body {
  /* min-height: 100%; */
  margin: 0;
  padding: 0;
  /* padding-left: 5px;
  padding-right: 5px; */
  font-family: "STIX Two Math";
  /* background-image: linear-gradient(90deg, #ffffff, #eeeeff); */
  /* background-image: linear-gradient(#ffffff, #eeeeff); */
}
* {
  box-sizing: border-box;
}
</style>