<template>
  <h1>{{ t("checkout.title") }}</h1>
  <div class="progress">
    <span
      ><router-link
        class="inactive"
        :to="{ name: 'cart', params: { lang: $i18n.locale } }"
        >{{ t("cart.showcart") }}</router-link
      ></span
    >
    <span>&GreaterGreater;</span> <span>{{ t("checkout.orderdetails") }}</span>
    <span>&GreaterGreater;</span>
    <span class="inactive">{{ t("checkout.payment") }}</span>
  </div>

  <form @submit.prevent="placeOrder">
    <div
      v-bind:key="section"
      v-for="[section, obj] in $store.getters.checkoutFields(this.checkout)"
    >
      <p>
        <span>{{ t("checkout." + section) }}</span>
      </p>
      <div v-bind:key="key" v-for="[key, model] in Object.entries(obj)">
        <textarea
          v-if="model.type == 'textarea'"
          :placeholder="getLabel(key, model)"
          :id="'input' + key"
          v-model="this.checkoutForm[key]"
        />
        <select
          v-if="model.type == 'country-dropdown'"
          :required="model.required"
          :id="'input' + key"
          v-model="this.checkoutForm.country"
        >
          <option value="" disabled>
            {{ t("checkout.country") }}
          </option>
          <option
            v-for="country in countries"
            :value="country.value"
            v-bind:key="country.key"
          >
            {{ country.label }}
          </option>
        </select>
        <input
          :type="model.type || 'text'"
          :id="'input' + key"
          v-if="model.type != 'country-dropdown' && model.type != 'textarea'"
          :required="model.required"
          :placeholder="getLabel(key, model)"
          v-model="this.checkoutForm[key]"
        />
        <p :id="'error' + key" class="error">Er is iets fout gegaan hier</p>
      </div>
    </div>
    <p id="errorgeneral" class="error">Er is iets fout gegaan hier</p>
    <input
      type="submit"
      :disabled="this.orderInProgress"
      :value="this.submitValue"
    />
    <easy-spinner
      v-if="this.orderInProgress"
      class="spinner"
      color="rgba(16, 65, 115, 0.95)"
      size="40"
    />
  </form>
</template>

<script>
import { useI18n } from "vue-i18n";
import axios from "axios";
const countries = require("i18n-iso-countries");
countries.registerLocale(require("i18n-iso-countries/langs/en.json"));
countries.registerLocale(require("i18n-iso-countries/langs/nl.json"));

export default {
  name: "Checkout",
  data() {
    return {
      now: Math.floor(Date.now() / 1000),
      orderInProgress: false,
      checkoutForm: {
        country: "",
      },
      checkout: {
        contact: {
          givenName: {
            required: true,
          },
          familyName: {
            required: true,
          },
          email: {
            required: true,
            type: "email",
          },
        },
        shipping: {
          streetAndNumber: {
            required: true,
          },
          postalCode: {
            required: true,
          },
          city: {
            required: true,
          },
          region: {
            required: false,
          },
          country: {
            required: true,
            type: "country-dropdown",
          },
        },
        sheetmusic: {
          personalized: {
            required: false,
          },
        },
        extra: {
          remarks: {
            required: false,
            type: "textarea",
          },
        },
      },
    };
  },
  computed: {
    webshopUrl() {
      return process.env.VUE_APP_WEBSHOP_URL;
    },
    submitValue() {
      if (this.orderInProgress) {
        const mod_value = 6;
        const nr_of_dots = this.now % mod_value;
        const nr_of_spaces = mod_value - nr_of_dots;
        return (
          this.$t("checkout.processing") +
          " " +
          ".".repeat(nr_of_dots) +
          " ".repeat(nr_of_spaces)
        );
      } else {
        const postfix = "(€" + this.$store.getters.totalCosts + ")";
        return this.$t("checkout.pay") + " " + postfix;
      }
    },
    countries() {
      const list = countries.getNames(this.$i18n.locale, {
        select: "official",
      });
      const countryOrder = ["NL", "BE", "FR", "DE", "GB", "US"];
      const initList = countryOrder.map((key) => ({
        value: key,
        label: list[key],
      }));
      const restList = Object.keys(list)
        .filter((key) => !countryOrder.includes(key))
        .map((key) => ({ value: key, label: list[key] }));
      return initList.concat(restList);
    },
  },
  setup() {
    return useI18n();
  },
  created() {
    var self = this;
    setInterval(function () {
      self.now = Math.floor(Date.now() / 500);
    }, 500);
  },
  mounted() {
    window.fbq("track", "Checkout", {
      value: this.$store.getters.totalCosts,
      quantity: this.$store.getters.totalProductQuantity,
      requires_shipping: this.$store.getters.requires_shipping,
    });
    if (localStorage.getItem("checkoutForm")) {
      this.checkoutForm = JSON.parse(localStorage.getItem("checkoutForm"));
    }
  },
  watch: {
    checkoutForm: {
      handler(newValue) {
        localStorage.setItem("checkoutForm", JSON.stringify(newValue));
      },
      deep: true,
    },
  },
  methods: {
    cleanCheckout: function () {
      this.orderInProgress = false;
      this.$store.commit("clearCart");
      this.checkoutForm = {
        country: "",
      };
    },
    placeOrder: function () {
      const details = {
        cart: this.$store.state.cart,
        coupon: this.$store.state.coupon,
        checkout: this.checkoutForm,
        locale: this.$i18n.locale,
      };

      this.orderInProgress = true;

      window.fbq("track", "orderPlacementAttempt", {
        cart: this.$store.state.cart,
      });

      axios
        .post(this.webshopUrl, JSON.stringify(details), {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((result) => {
          window.fbq("track", "ProceedPayment", {
            cart: this.$store.state.cart,
          });
          this.cleanCheckout();
          window.location.href = result.data;
        })
        .catch((error) => {
          window.fbq("track", "WebShopError", {
            cart: this.$store.state.cart,
            status: error.response.status,
            data: error.response.data,
          });
          this.orderInProgress = false;
          const key = error.response.data.field
            ? error.response.data.field
            : "general";
          const el = document.getElementById("error" + key);
          const input = document.getElementById("input" + key);
          el.classList.add("show");
          el.innerHTML = this.$t("checkout." + error.response.data.message);

          if (input) {
            input.classList.add("errorBorder");
            input.focus();
          }

          setTimeout(() => {
            el.classList.remove("show");
            if (input) {
              input.classList.remove("errorBorder");
            }
          }, 5000);
        });
    },
    getLabel: function (key, model) {
      const res = this.$t("checkout." + key);
      const opt = !model.required
        ? " (" + this.$t("checkout.optional") + ")"
        : "";
      return res + opt;
    },
  },
};
</script>

<style scoped>
form {
  padding-left: 25px;
  padding-right: 25px;
  margin-bottom: 30px;
}

.error {
  margin-top: -12px;
  color: rgb(184, 15, 15);
  display: none;
  font-size: 11px;
}

.show {
  display: block;
}

select {
  width: 100%;
  padding: 8px;
  margin-bottom: 15px;
  -webkit-appearance: none;
}

select:invalid {
  color: gray;
}

.errorBorder {
  border: 1px solid rgb(184, 15, 15);
}

label {
  width: 35%;
  display: inline-block;
  text-align: right;
  padding-right: 10px;
  padding-bottom: 10px;

  font-family: "STIX Two Math", "Lato", "Merriweather", "Truculenta", "Lekton",
    "Puritan", "Neutral", "Helvetica Neue";
  font-size: 18px;
  letter-spacing: 1.5px;
  line-height: 1.8;
}
input {
  width: 100%;
  padding: 8px;
  margin-bottom: 15px;
}

input[type="submit"] {
  background-color: rgba(16, 65, 115, 0.95);
  color: white;
  border: 0px;
  cursor: pointer;
  padding: 10px;
  -webkit-appearance: none;
}

input[type="submit"]:disabled {
  background-color: gray;
  cursor: default;
}

.spinner {
  margin-left: calc(50% - 20px);
}

textarea {
  width: 100%;
  padding: 8px;
  margin-bottom: 12px;
  font-family: Helvetica;
  height: 60px;
}

p {
  margin-bottom: unset;
  padding-left: unset;
}

.actions {
  padding-left: 25px;
  padding-right: 25px;
  margin-bottom: 30px;
}

.progress {
  padding-left: 25px;
  padding-right: 25px;
  font-size: 12px;
  font-family: helvetica;
}

.inactive {
  color: gray;
}

active {
  color: black;
  text-decoration: underline;
}

.progress span {
  padding: 3px;
}

a {
  text-decoration: none;
}

@media only screen and (min-width: 900px) {
  form {
    padding-left: min(5vw, 8vh);
    padding-right: min(5vw, 8vh);
    margin-bottom: 30px;
  }

  .progress {
    padding-left: min(5vw, 8vh);
    padding-right: min(5vw, 8vh);
    font-size: min(1.1vw, 2vh);
  }

  .error {
    font-size: unset;
  }
}

@media only screen and (min-width: 1400px) {
  input {
    font-size: min(1.2vw, 2.2vh);
  }
  textarea {
    font-size: min(1.2vw, 2.2vh);
  }
  select {
    font-size: min(1.2vw, 2.2vh);
  }
}
</style>