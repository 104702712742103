<template>
  <div class="row">
    <div class="col-6 left-side bio">
      <img src="../assets/basbeelen1.jpg" />
    </div>
    <div class="col-6" id="bio">
      <h1>{{ t("bio.title") }}</h1>
      <p v-html="t('bio.sections.section1')"></p>
      <p v-html="t('bio.sections.section2')"></p>
      <p v-html="t('bio.sections.section3')"></p>
      <p v-html="t('bio.sections.section4')"></p>
    </div>
  </div>
</template>

<script>
import { useI18n } from "vue-i18n";
export default {
  name: "About",
  setup() {
    return useI18n();
  },
};
</script>

<style scoped>
@media only screen and (min-width: 900px) {
  .bio {
    background-image: url("../assets/basbeelen1.jpg");
    background-position: 0px;
  }
}
@media only screen and (max-height: 50vw) {
  .bio {
    background-position: calc((100vw - 200vh) / 2);
    background-size: 110vh;
    /* border: 4px solid green; */
  }
}
</style>