import shop from "./data/shop.json";

export default {
    addToCart: function (event) {
        const item = shop.catalog[event.id];

        //google
        const data = {
            currenty: "EUR",
            value: (item.price || item.min_price) * event.addition,
            item: [{
                item_id: event.id,
                item_name: item.language.en.title
            }]
        }
        window.gtag("event", "add_to_cart", data);
        window.fbq("track", "AddToCart", data);
    },
    viewCart: function (items, value) {
        const ga_items = Object.values(items).map(i => {
            return {
                item_id: i.id,
                item_name: i.language.en.title
            }
        })
        const data = {
            currency: "EUR",
            value: value,
            items: ga_items
        }
        window.gtag("event", "view_cart", data);
    },
    openSpotify: function (details, event_id) {
        window.fbq("consent", "grant");
        window.fbq("trackCustom", "Open Spotify", details, { eventID: event_id });
    }
}