<template>
  <h1>{{ t("cart.title") }}</h1>

  <p class="empty" v-if="$store.getters.cartIsEmpty">{{ t("cart.empty") }}</p>
  <div v-if="!$store.getters.cartIsEmpty" id="container">
    <div
      v-for="item in this.$store.getters.cartItems"
      v-bind:key="item.id"
      class="cartItem"
    >
      <div class="title">
        <p>{{ item.quantity }}x {{ item.language[this.$i18n.locale].title }}</p>
      </div>
      <div class="price">
        <p>€{{ item.quantity * item.price }}</p>
      </div>
    </div>
    <div
      class="cartItem"
      v-bind:key="item"
      v-for="item in this.$store.getters.coupons"
    >
      <div class="title shipping">
        <p>
          {{ t("cart.coupon") }} <i>{{ item.id }}</i>
        </p>
        <div>
          {{ t(item.description) }}
        </div>
      </div>
      <div class="price">
        <p>- €{{ item.value }}</p>
      </div>
    </div>
    <hr />
    <div class="cartItem">
      <div class="title">
        <p>{{ t("cart.subtotal") }}</p>
      </div>
      <div class="price">
        <p>€{{ $store.getters.subtotal }}</p>
      </div>
    </div>

    <div class="cartItem" v-if="this.$store.getters.requires_shipping">
      <div class="title shipping">
        <p>{{ t("cart.shipping") }}</p>
        <div>
          {{ t("cart.freeshipping") }} €{{
            this.$store.getters.free_shipping_treshold
          }},-
        </div>
      </div>
      <div class="price">
        <p>€{{ $store.getters.shippingCosts }}</p>
      </div>
    </div>
    <div class="cartItem">
      <div class="title">
        <p class="bold">{{ t("cart.total") }}</p>
      </div>
      <div class="price">
        <p class="bold">€{{ $store.getters.totalCosts }}</p>
      </div>
    </div>
  </div>

  <div class="sidenote" v-if="!$store.getters.cartIsEmpty">
    <div>
      {{ t("cart.haveacoupon") }}
    </div>
    <div>
      <form @submit.prevent="redeem">
        <input type="text" id="couponCode" /><input
          type="submit"
          :value="$t('cart.redeem')"
        />
      </form>
    </div>
    <div class="small" v-html="t('cart.getcoupon')" />
  </div>

  <div class="actions">
    <router-link
      :to="{ name: 'shop', params: { lang: this.$i18n.locale }, hash: '#shop' }"
    >
      <div>{{ t("cart.continue") }}</div></router-link
    >
    <router-link
      v-if="!$store.getters.cartIsEmpty"
      :to="{
        name: 'checkout',
        params: { lang: this.$i18n.locale },
        hash: '#shop',
      }"
      ><div>{{ t("cart.checkout") }}</div></router-link
    >
  </div>
</template>

<script>
import { useI18n } from "vue-i18n";

import shopItems from "../data/shop.json";
import Coupon from "../model/Coupon.js";

export default {
  name: "Cart",
  data() {
    return {
      salt: this.$CryptoJS.enc.Base64.parse("vJslhxLPgGAQ3pm1Ef9nXA=="),
      shopItems: shopItems.catalog,
      coupons: shopItems.coupons,
    };
  },
  mounted() {
    // this.$forceUpdate();
    this.$analytics.viewCart(
      this.$store.getters.cartItems,
      this.$store.getters.totalCosts
    );
    if (!this.$store.getters.validUserPrices) {
      this.$router.push({
        name: "shop",
        params: { lang: this.$i18n.locale },
      });
    }
  },
  setup() {
    return useI18n();
  },
  methods: {
    redeem() {
      const codeField = document.getElementById("couponCode");
      let code = codeField.value;
      if (Coupon.validate(code).length) {
        codeField.value = "";
        this.$store.commit("addCoupon", code);
        this.$forceUpdate();
      } else {
        codeField.classList.add("errorBorder");
        // codeField.focus();

        setTimeout(() => {
          codeField.classList.remove("errorBorder");
        }, 5000);
      }
    },
  },
};
</script>

<style scoped>
.cartItem {
  padding-left: 25px;
  padding-right: 25px;
  width: 100%;
  display: flex;
}
.cartItem:last-child {
  margin-bottom: 25px;
}

.cartItem p {
  padding-left: 5px;
  /* text-align: center; */
  padding-right: 5px;
  margin-top: unset;
  padding-bottom: unset;
  margin-bottom: unset;
  float: left;
  /* line-height: unset; */
}

.coupon {
  padding-left: 25px;
  padding-right: 25px;
  width: 100%;
  margin-bottom: 35px;
}

.errorBorder {
  outline: 2px solid rgb(184, 15, 15);
  color: rgb(184, 15, 15);
  font-weight: bold;
}

.coupon div {
  display: block;
}

.coupon p {
  padding-left: 5px;
  /* text-align: center; */
  padding-right: 5px;
  margin-top: unset;
  padding-bottom: unset;
  margin-bottom: unset;
}

input {
  padding: 8px;
  /* margin-bottom: 15px; */
}

input[type="submit"] {
  background-color: rgba(16, 65, 115, 0.95);
  color: white;
  padding: 10px;
  border: 0px;
  -webkit-appearance: none;
}

.shipping p {
  float: unset;
}

.small {
  font-style: italic;
  font-size: 14px;
  margin-top: 6px;
}

.small a {
  font-size: 11px;
}

.bold {
  font-weight: bold;
  color: rgba(16, 65, 115, 0.95);
}

.cartItem .title {
  width: 80%;
  /* float: left; */
}

.cartItem .quantity {
  width: 25%;
  float: right;
  text-align: center;
}

.cartItem .price {
  width: 20%;
  float: right;
}

.cartItem .price p {
  float: right;
}

/* .cartItem .shipping {
  width: 80%;
} */

.dotted-spaced {
  background-image: linear-gradient(
    to right,
    #333 10%,
    rgba(255, 255, 255, 0) 0%
  );
  background-position: top;
  background-size: 10px 1px;
  background-repeat: repeat-x;
}

hr {
  border-top: dotted 1px;
  width: 90%;
}

.cartItem .shipping p {
  width: 65%;
  /* display: block; */
}

.cartItem .title div {
  /* display: inline-block; */
  font-style: italic;
  font-size: 14px;
  margin-top: -4px;
  margin-left: 5px;
  margin-bottom: 10px;
  text-align: left;
}

.cartItem .quantity div {
  cursor: pointer;
  /* font-size: 20px; */
  /* display: inline-block; */
  color: white;
  border-radius: 50%;
  border-color: black;
  /* padding-top: 16px;
  padding-left: 6px; */
  height: 20px;
  width: 20px;
  text-align: center;
  font-weight: 800;
  background-color: rgba(16, 65, 115, 0.95);
  /* line-height: 0; */
  position: relative;
  margin-top: 5px;
  margin-bottom: 10px;
  float: left;
}

.actions {
  padding-left: 25px;
  padding-right: 25px;
  margin-bottom: 30px;
}

.empty {
  text-align: center;
}

.actions div {
  background-color: rgba(16, 65, 115, 0.95);
  color: white;
  width: 48%;
  display: inline-block;
  text-align: center;
  padding: 10px;
  margin-left: 2%;
  cursor: hand;
  /* font-size: 12px; */
}

@media only screen and (min-width: 900px) {
  .cartItem {
    padding-left: 5vw;
    padding-right: 5vw;
  }

  .small {
    font-style: italic;
    font-size: 1vw;
    margin-top: 10px;
  }

  .small a {
    font-size: 0.8vw;
  }

  .coupon {
    padding-left: 5vw;
    padding-right: 5vw;
  }

  hr {
    width: 80%;
  }

  .cartItem .title div {
    /* display: inline-block; */
    font-size: min(1.1vw, 2.4vh);
  }

  .actions {
    padding-left: 5vw;
    padding-right: 5vw;
    margin-bottom: 30px;
    font-size: min(1.4vw, 2.7vh);
  }
}
</style>