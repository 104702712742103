<template>
  <h1>{{ t("thankyou.title") }}</h1>
  <p>
    <span class="sign">&#10003;</span><br />
    {{ t("thankyou.yourorder") }} <span>{{ $route.params.orderId }}</span>
    {{ t("thankyou.successful") }}
  </p>
  <p>{{ t("thankyou.willbeshipped") }}</p>
  <p>
    <router-link :to="{ name: 'shop', params: { lang: this.$i18n.locale } }">{{
      t("thankyou.redirected")
    }}</router-link>
  </p>
</template>

<script>
import { useI18n } from "vue-i18n";
export default {
  name: "ThankYou",
  setup() {
    return useI18n();
  },
  mounted() {
    setTimeout(
      () =>
        this.$router.push({
          name: "language",
          params: { lang: this.$i18n.locale },
        }),
      5000
    );
  },
};
</script>

<style scoped>
p {
  text-align: center;
}

.sign {
  font-size: 100px;
  padding-top: -40px;
  margin-bottom: -40px;
}
</style>