<template>
  <div class="row">
    <div class="col-6 relative" id="live">
      <a href="https://www.songkick.com" target="_blank"
        ><img
          class="logo songkick"
          src="../assets/powered-by-songkick-black.png"
      /></a>
      <h1>{{ t("live.title") }}</h1>

      <p>
        <span>{{ t("live.futuregigs") }}</span>
      </p>
      <div
        class="gig-container"
        v-for="gig in gigs"
        v-bind:key="gig.id"
        @click="open(gig.uri)"
      >
        <div class="tcol">
          <div class="date">
            {{ new Date(gig.start.date).getDate().toString().padStart(2, "0") }}
          </div>
          <div>{{ getMonth(gig.start.date) }}</div>
        </div>
        <!-- <div class="tcol">{{ gig.start.time.substring(0, 5) }}</div> -->
        <div class="tcol">
          <div>{{ gig.venue.displayName }}</div>
        </div>
        <div class="tcol right">
          <div>{{ gig.location.city }}</div>
        </div>
      </div>
      <span class="noGigs" v-if="noGigs()"
        >{{ t("live.nogigs") }} <br /><br />
        <a href="mailto:mail@basbeelen.com">{{ t("live.bookgig") }}</a></span
      >

      <p>
        <span>{{ t("live.pastgigs") }}</span>
      </p>

      <div
        class="gig-container"
        v-for="gig in pastGigs"
        v-bind:key="gig.id"
        @click="open(gig.uri)"
      >
        <div class="tcol">
          <div class="date">
            {{ new Date(gig.start.date).getDate().toString().padStart(2, "0") }}
          </div>
          <div>{{ getMonth(gig.start.date) }}</div>
        </div>
        <!-- <div class="tcol">{{ gig.start.time.substring(0, 5) }}</div> -->
        <div class="tcol">
          <div>{{ gig.venue.displayName }}</div>
        </div>
        <div class="tcol right">
          <div>{{ gig.location.city }}</div>
        </div>
      </div>
    </div>
    <div class="col-6 live"><img src="../assets/baslive.jpg" /></div>
  </div>
</template>

<script>
import { useI18n } from "vue-i18n";
import axios from "axios";

export default {
  name: "Gigs",
  data() {
    return {
      gigs: [
        // {
        //   url: "foo",
        //   start: {
        //     date: "2021-01-05",
        //   },
        //   venue: {
        //     displayName: "Foo",
        //   },
        //   location: {
        //     city: "Utrecht",
        //   },
        // },
      ],
      pastGigs: [],
    };
  },
  created() {
    this.getGigs();
    this.getPastGigs();
  },
  setup() {
    return useI18n();
  },
  methods: {
    open(uri) {
      window.open(uri, "_blank").focus();
    },
    getMonth(date) {
      const d = new Date(date);
      const month = this.t("months." + d.getMonth());
      return month.toUpperCase().substring(0, 3);
    },
    formatDate(date) {
      const d = new Date(date);
      // const weekday = this.t("weekdays." + d.getDay());
      const month = this.t("months." + d.getMonth());
      const monthday = d.getDate();
      const year = d.getFullYear();
      return this.$i18n.locale == "nl"
        ? monthday + " " + month + " " + year
        : month + " " + monthday + " " + year;
    },
    weekday(date) {
      const d = new Date(date);
      return this.t("weekdays." + d.getDay());
    },
    noGigs() {
      return this.gigs == undefined || this.gigs.length == 0;
    },
    getGigs() {
      axios
        .get(
          "https://api.songkick.com/api/3.0/artists/10179135/calendar.json?apikey=VgOeG4TUuURr2Lys"
        )
        .then((data) => {
          this.gigs = data.data.resultsPage.results.event;
        })
        .catch(() => {
          this.gigs = [];
        });
    },
    getPastGigs() {
      axios
        .get(
          "https://api.songkick.com/api/3.0/artists/10179135/gigography.json?apikey=VgOeG4TUuURr2Lys"
        )
        .then((data) => {
          this.pastGigs = data.data.resultsPage.results.event;
          this.pastGigs.reverse();
        })
        .catch(() => {
          this.gigs = [];
        });
    },
  },
};
</script>

<style lang="css" scoped>
a {
  color: darkblue;
}
.relative {
  position: relative;
  padding-left: 25px;
  padding-right: 25px;
  padding-bottom: 50px;
  text-align: center;
  font-family: "STIX Two Math", "Lato", "Merriweather", "Truculenta", "Lekton",
    "Puritan", "Neutral", "Helvetica Neue";
  letter-spacing: 1.5px;
}
.relative h1 {
  padding-right: 25px;
}
.relative p {
  /* padding-left: 5px; */
  padding-bottom: unset;
  text-align: center;
}
.relative .noGigs {
  font-style: italic;
  /* padding-top: 50px; */
  display: block;
  padding-bottom: 30px;
}

.weekday {
  font-size: 10px;
  font-weight: bold;
}
.city {
  text-align: right;
}
.songkick {
  top: 45px;
  width: 85px;
  right: 15px;
  position: absolute;
  display: unset;
}

.gigs {
  width: 100%;
  margin-bottom: 50px;
  /* border: 1px solid black; */
}

.gig-container {
  display: flex;
  cursor: pointer;
  text-align: left;
  padding: 10px;
  align-items: center;
}

.gig-container .tcol:not(:first-child) {
  padding-left: 3vw;
}

.gig-container .tcol:last-child {
  flex-grow: 1;
  text-align: right;
}

.gig-container .date {
  /* font-family: "Helvetica Neue"; */
  font-size: 30px;
}

.right {
  text-align: right;
  float: right;
}

@media only screen and (min-width: 900px) {
  span {
    font-size: min(1.3vw, 2.6vh);
  }
  .gig-container {
    padding-left: min(5vw, 8vh);
    padding-right: min(5vw, 8vh);
    font-size: min(1.2vw, 2.5vh);
  }
  .gig-container .date {
    /* font-family: "Helvetica Neue"; */
    font-size: max(1.6vw, 4vh); /*min(2.4vw, 2.7vh);*/
  }
  .weekday {
    font-size: 2.4vw;
    font-weight: bold;
  }
  .live {
    background-image: url("../assets/baslive.jpg");
    background-size: 120vh;
    background-position-y: -30vh;
    background-position-x: -100px;
    transform: scaleX(-1);
  }
  .songkick {
    top: 50px;
    left: 50px;
    position: absolute;
  }
}
@media only screen and (min-width: 1400px) {
  .live {
    background-image: url("../assets/baslive.jpg");
    background-size: 130vh;
    background-position-y: -40vh;
    background-position-x: -100px;
    transform: scaleX(-1);
  }
}
</style>