<template>
  <div class="row" id="news">
    <div class="col-6">
      <h1>{{ t("news.title") }}</h1>
      <form @submit.prevent="subscribe">
        <div class="sidenote">
          {{ t("news.newsletter") }} <br />
          <input
            type="text"
            required
            v-model="subscription.model.email"
            :placeholder="$t('news.placeholder')"
            id="subscription_input"
          />
          <input
            type="submit"
            :value="$t('news.subscribe')"
            :disabled="subscription.processing"
          />
          <div class="error" v-if="subscription.errorMsg">
            {{ t(subscription.errorMsg) }}
          </div>
        </div>
      </form>
      <div class="messages" id="messages">
        <div
          v-for="post in posts.slice(0, nrOfNewsItems)"
          v-bind:key="post.date"
          class="message"
        >
          <p class="header">
            <span>{{ post.date }}</span
            >&nbsp;&nbsp;<span class="header-title">{{
              post.contents[this.$i18n.locale].title
            }}</span>
          </p>
          <div class="row vertical" v-if="post.video && post.video.isPortrait">
            <div class="col-6">
              <div class="sections">
                <p
                  class="section"
                  v-for="section in post.contents[this.$i18n.locale].sections"
                  v-bind:key="section"
                  v-html="section"
                ></p>
              </div>
              <!-- <p style="padding-right: 3vw">
                {{ post.}}
              </p> -->
            </div>
            <div class="col-6">
              <iframe
                class="portrait-video"
                :src="'https://www.youtube-nocookie.com/embed/' + post.video.id"
              ></iframe>
            </div>
          </div>
          <div v-if="!post.video || !post.video.isPortrait">
            <div class="sections">
              <p
                class="section"
                v-for="section in post.contents[this.$i18n.locale].sections"
                v-bind:key="section"
                v-html="section"
              ></p>
            </div>
            <div class="videoembed" v-if="post.video">
              <LiteYouTubeEmbed
                v-bind:title="post.title || ''"
                v-bind:id="post.video.id"
                poster="maxresdefault"
                width="540"
                height="960"
                params="cc_load_policy=1&cc_lang_pref={{ this.$i18n.locale }}&w=540&h=960"
              />
            </div>
          </div>
        </div>
        <p class="plink" style="float: left">
          <a v-if="nrOfNewsItems > 3" @click="lessNews">{{
            t("menu.lessNews")
          }}</a>
        </p>
        <p class="plink" style="float: right">
          <a v-if="nrOfNewsItems < posts.length" @click="moreNews">{{
            t("menu.moreNews")
          }}</a>
        </p>
      </div>
    </div>
    <div class="col-6 right-side news">
      <img src="../assets/shoe-pedal-2.jpg" />
    </div>
  </div>
</template>

<script>
import { useI18n } from "vue-i18n";
import posts from "../data/news.json";
import LiteYouTubeEmbed from "vue-lite-youtube-embed";
import axios from "axios";

export default {
  name: "News",
  data() {
    return {
      nrOfNewsItems: 3,
      posts: posts.filter((item) => {
        return !item.hide;
      }),
      subscription: {
        processing: false,
        submitted: false,
        errorMsg: "",
        model: {
          email: "",
        },
      },
    };
  },
  components: {
    LiteYouTubeEmbed,
  },
  setup() {
    return useI18n();
  },
  methods: {
    lessNews() {
      this.nrOfNewsItems -= 2;
    },
    moreNews() {
      this.nrOfNewsItems += 2;
    },
    clearSubscription() {
      let input = document.getElementById("subscription_input");
      input.classList.remove("success");
      input.focus();
      this.subscription.submitted = false;
      this.subscription.processing = false;
      this.subscription.model.email = "";
    },
    subscribe() {
      this.subscription.processing = true;
      document.activeElement.blur();
      axios
        .post(
          "https://europe-west1-basbeelen-com.cloudfunctions.net/newsletter",
          JSON.stringify(this.subscription.model),
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then(() => {
          this.subscription.submitted = true;
          document
            .getElementById("subscription_input")
            .classList.add("success");
          setTimeout(this.clearSubscription, 1500);
        })
        .catch((error) => {
          this.subscription.errorMsg = error.response.data;
          setTimeout(() => {
            let input = document.getElementById("subscription_input");
            input.setSelectionRange(0, input.value.length);
            input.focus();
            this.subscription.errorMsg = "";
            this.subscription.processing = false;
          }, 2000);
        });
    },
  },
};
</script>

<style lang="css" scoped>
.message {
  margin-bottom: 40px;
}

.header {
  /* background-color: green; */
  padding-bottom: 0px;
}

.header-title {
  font-style: italic;
  font-weight: bold;
  color: unset;
}

input {
  padding: 18px;
}

input[type="submit"] {
  background-color: rgba(16, 65, 115, 0.95);
  color: white;
  border: 0px;
  cursor: pointer;
  padding: 10px;
  -webkit-appearance: none;
}

.vertical .section {
  margin-top: -5px;
  padding-right: min(3vw, 40px);
}

.portrait-video {
  width: calc(100vw - 50px);
  height: calc(1.77 * 100vw - 50px);
  border: 0px;
  margin-right: 25px;
  margin-left: 25px;
  margin-bottom: 30px;
}

.ignore {
  all: unset;
}

.videoembed {
  padding-left: 25px;
  padding-right: 25px;
}

@media only screen and (min-width: 900px) {
  .videoembed {
    padding-left: min(5vw, 8vh);
    padding-right: min(5vw, 8vh);
  }
  .news {
    background-image: url("../assets/shoe-pedal-2.jpg");
    background-position: 50vw;
    background-size: 100vh;
  }

  .newsletter {
    font-size: min(1vw, 2.2vh);
  }

  .newsletter input {
    font-size: min(1vw, 2.2vh);
  }

  .portrait-video {
    width: 20vw;
    height: 35.55vw;
    border: 0px;
    /* right: min(-5vw, -60px); */
    margin-left: unset;
    margin-right: unset;
    /* margin-right: 5vw; */
    /* margin-right : 5vw; */
  }
}
@media only screen and (min-width: 1400px) {
  .portrait-video {
    width: calc(50vh - 8vh);
    height: calc(1.77 * (50vh - 8vh));
    border: 0px;
    /* right: min(-5vw, -60px); */
    margin-left: unset;
    margin-right: unset;
    /* margin-right: 5vw; */
    /* margin-right : 5vw; */
  }
}
</style>