<template>
  <nav class="topnav" tabindex="0" id="topnav" @keydown.esc="closeMenu">
    <div class="opener_container">
      <span id="opener" @click="swapMenu">&#x2630;</span>
    </div>
    <div class="language">
      <span
        tabindex="0"
        id="language_nl"
        @click="swapLanguage('nl')"
        @keydown.enter="swapLanguage('nl')"
        v-bind:class="{ active: this.isDutch }"
        >NL</span
      >
      |
      <span
        tabindex="0"
        id="language_en"
        @click="swapLanguage('en')"
        @keydown.enter="swapLanguage('en')"
        v-bind:class="{ active: !this.isDutch }"
        >EN</span
      >
    </div>
    <div
      id="menu_container"
      @click="closeMenu"
      class="menu_container"
      @keydown.esc="closeMenu"
    >
      <div class="menu-left">
        <a href="#album" @click="closeMenu">{{ t("menu.album") }}</a>
        <a href="#news" @click="closeMenu">{{ t("menu.news") }}</a
        ><a href="#bio" @click="closeMenu">{{ t("menu.bio") }} </a>
      </div>
      <div class="menu-header">
        <div class="title">bas &nbsp;&nbsp;&nbsp;beelen</div>
        <!-- <div class="subsub">{{ t("menu.subsubtitle") }}</div> -->
        <!-- <hr /> -->
        <!-- <div class="sub">{{ t("menu.epk") }}</div> -->
      </div>
      <div class="menu-right">
        <a href="#media" @click="closeMenu">{{ t("menu.media") }}</a>
        <!-- <a href="#photos" @click="closeMenu">{{ t("menu.photos") }}</a
        > -->
        <a href="#live" @click="closeMenu">{{ t("menu.live") }}</a>
        <a href="#shop" @click="closeMenu">{{ t("menu.shop") }}</a>
        <a href="#contact" @click="closeMenu">{{ t("menu.contact") }}</a>
      </div>
    </div>
  </nav>
</template>

<script>
import { useI18n } from "vue-i18n";

export default {
  name: "NavBar",
  setup() {
    return useI18n();
  },
  computed: {
    isDutch: function () {
      return this.$i18n.locale == "nl";
    },
  },
  created() {
    let vh = window.innerHeight * 0.01;
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty("--vh", `${vh}px`);
    window.addEventListener("resize", () => {
      if (window.innerWidth >= 900) {
        this.closeMenu();
      }
      let vh = window.innerHeight * 0.01;
      // Then we set the value in the --vh custom property to the root of the document
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    });
  },
  methods: {
    getTopNav: function () {
      return [
        document.getElementById("topnav"),
        document.getElementById("menu_container"),
        document.getElementsByTagName("body")[0],
      ];
    },
    closeMenu: function () {
      let [topNav, innerContainer, body] = this.getTopNav();
      body.classList.remove("prevent-scrolling");
      topNav.classList.remove("overlay");
      innerContainer.classList.remove("valign");
      document.getElementById("opener").innerHTML = "&#x2630";
      window.scrollTo(0, localStorage.getItem("scrollY"));
    },
    openMenu: function () {
      let [topNav, innerContainer, body] = this.getTopNav();
      localStorage.setItem("scrollY", window.scrollY);
      document.body.style.top = `-${window.scrollY}px`;
      topNav.classList.add("overlay");
      innerContainer.classList.add("valign");
      document.getElementById("opener").innerHTML = "&#x2715";
      body.classList.add("prevent-scrolling");
    },
    swapMenu: function () {
      let [topNav, foo] = this.getTopNav();
      foo;
      const opened = topNav.classList.contains("overlay");
      opened ? this.closeMenu() : this.openMenu();
    },
    swapLanguage: function (lang) {
      this.$router.push({
        name: this.$route.name || "language",
        params: { lang: lang },
      });
      this.closeMenu(); // only because Safari doesn't properly bind the class to underline the active language
    },
  },
};
</script>

<style>
.prevent-scrolling {
  overflow-y: hidden;
  position: fixed;
}
nav {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  /* height: 50px; */
  /* overflow: hidden; */
  /* background-color: rgba(16, 44, 85, 1); rgb(9, 1, 48); */
  z-index: 5;
}

.overlay {
  overflow-x: hidden;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  color: white;
  overflow: default;
  background-color: rgba(9, 1, 48, 0.95);
  /* transition: 0.3s all; */
}

.overlay .language {
  display: unset;
}

.overlay a {
  color: white;
}

/* .overlay #opener a {
  color: white;
} */

.opener_container {
  position: absolute;
  right: 5px;
  top: 14px;
}

#opener {
  cursor: pointer;
  font-size: 20px;
  display: inline-block;
  color: white;
  border-radius: 50%;
  border-color: black;
  padding-top: 16px;
  padding-left: 6px;
  height: 30px;
  background-color: rgba(9, 1, 48, 0.95);
  width: 30px;
  line-height: 0;
  position: relative;
}

.menu_container {
  display: none;
}

nav .menu-left {
  padding-top: 2.5vw;
  text-align: center;
  width: 100%;
}
nav .menu-header {
  padding-top: 2.5vw;
  text-align: center;
  width: 100%;
}
nav .menu-right {
  padding-top: 2.5vw;
  width: 100%;
  text-align: center;
}
nav a {
  font-family: "Oswald";
  text-decoration: none;
  font-size: 1em;
  color: white;
  margin: 10px;
  display: block;
}

nav .title {
  letter-spacing: -2px;
  font-size: 42px;
  padding: 2px;
  font-family: onelove;
  background-image: linear-gradient(
    to right,
    #462523 0,
    #cb9b51 22%,
    #f6e27a 45%,
    #f6f2c0 50%,
    #f6e27a 55%,
    #cb9b51 78%,
    #462523 100%
  );
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
  /* background-clip: padding-box; */
}

nav .sub {
  font-style: italic;
  font-size: 14px;
  letter-spacing: -1px;
  margin-top: -15px;
  margin-bottom: 15px;
  font-family: "STIX Two Math", "Oswald";
  background-image: linear-gradient(
    to right,
    #462523 0,
    #cb9b51 22%,
    #f6e27a 45%,
    #f6f2c0 50%,
    #f6e27a 55%,
    #cb9b51 78%,
    #462523 100%
  );
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}
nav .subsub {
  font-size: 15px;
  font-family: "League Script";
}

hr {
  border: none;
  height: 1px;
  width: 180px;
  background-color: #999999;
}

.topnav::after {
  content: "";
  clear: both;
  display: table;
}

.valign {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: block;
}

.language {
  display: none;
  font-family: helvetica, arial;
  text-align: center;
  cursor: pointer;
  width: 100%;
  color: white;
  position: absolute;
  top: 50px;
  font-size: 13px;
}

.language .active {
  border-bottom: 3px solid white;
}

@media only screen and (min-width: 900px) {
  nav .title {
    font-size: 71px;
  }
}
/* .menu_container {
    position: fixed;
    width: 50%;
    background-color: rgba(9, 1, 9, 0.74);
  }
  #opener {
    display: none;
  }
  nav {
    height: unset;
    position: relative;
  }
  nav .title {
    font-size: 5vw;
  }
  nav a {
    display: unset;
    font-size: 0.8em;
  }
  nav .subsub {
    font-size: 0.5vw;
  }
  nav .sub {
    margin-top: -0.9vw;
    font-size: 1vw;
  }
  hr {
    width: 100%;
  }
  nav .menu-header {
    width: 30%;
    padding-top: 15px;
  }
  nav .menu-left {
    width: 35%;
    text-align: right;
    display: block;
  }
  nav .menu-right {
    display: block;
    text-align: left;
    width: 35%;
  }
  nav .menu-right .language {
    font-family: Helvetica;
    width: 100%;
    text-align: right;
  }
} */
/* 
@media only screen and (min-width: 1200px) {
  nav .sub {
    margin-top: -0.8vw;
    font-size: 0.9vw;
  }
  nav .title {
    font-size: 4.6vw;
  }
  hr {
    width: 100%;
  }
  nav .menu-header {
    width: 30%;
  }
  nav .menu-left {
    display: block;
  }
  nav .menu-right {
    display: block;
  }
} */
</style>