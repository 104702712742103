import { createApp } from 'vue'
import { createI18n } from 'vue-i18n'
import { store } from './store'
import VueGtag from "vue-gtag";
import router from './router'
import 'viewerjs/dist/viewer.css'
import VueViewer from 'v-viewer'
import App from './App.vue'
import easySpinner from 'vue-easy-spinner';
import VueCryptojs from 'vue-cryptojs';
import analytics from "./analytics.js";


const i18n = createI18n({
    globalInjection: true,
    legacy: false,
    warnHtmlMessage: false,
    locale: 'nl',
    messages: {
        en: {
            title: "Bas Beelen",
            invalid_email: "The email address provided is invalid.",
            min_price_not_met: "A product price is lower than the minimum price",
            menu: {
                epk: "electronic press kit",
                album: "ALBUM",
                bio: "ABOUT",
                news: "NEWS",
                media: "MEDIA",
                shop: 'SHOP',
                photos: "PHOTOS",
                live: "LIVE",
                contact: "CONTACT",
                subsubtitle: "composer & pianist",
                moreNews: "show more",
                lessNews: "show less"
            },
            privacy: {
                policy: "Privacy policy"
            },
            weekdays: {
                0: "Sunday",
                1: "Monday",
                2: "Tuesday",
                3: "Wednesday",
                4: "Thursday",
                5: "Friday",
                6: "Saturday"
            },
            months: {
                0: "January",
                1: "February",
                2: "March",
                3: "April",
                4: "May",
                5: "June",
                6: "July",
                7: "August",
                8: "September",
                9: "October",
                10: "November",
                11: "December"
            },
            album: {
                title: "Debut album",
                getacopy: "Name your price!",
                sheetmusic: "sheet music",
                sections: {
                    section1: "While working from home for a year, an awakened appreciation for the piano and a wellspring of pent-up creativity spark the genesis of Bas Beelen’s debut album <span>Abracadabra</span>. The name evolves from the title track’s main motif that, in a magical, <span>musical cryptogram</span>, actually spells this exclamation. With engaging melodies, alluring rhythms and surprising transitions, Beelen conjures up a <span>frolicking tuneful tale</span>, told through eleven solo piano pieces.",
                    section2: "The <span>Romantic-era dynamics</span> and the free-flowing <span>colourful harmonies</span> nod to Rachmaninoff and Debussy as influences, respectively. Yet, the presence of pop song structures and <span>catchy melodies</span> in irregular time signatures equally reveal more <span>contemporary inspiration</span>, such as Sufjan Stevens, Patrick Watson and the Avishai Cohen Trio.",
                    section3: "The result is a <span>kaleidoscope of sound</span>, an eclectic blend of classical music with indie-subgenres and a hint of jazz. Beelen shines his <span>own unique light</span> on this divers instrument to show that the piano can amaze, delight, engage and invigorate. Listen and be enchanted. <span>Abracadabra!</span>",
                    section4: ""
                },
                bla: ["one", "two", "three"]
            },
            bio: {
                title: "About Bas",
                sections: {
                    section1: "<span>Data engineer</span> by day, aspiring <span>composer</span> by night, Bas Beelen took his first lessons in classical piano at age seven together with his father. Initially encouraged with <span>sweets</span> to keep practicing, he eventually developed the intrinsic motivation to play and a love for music in general. In his teen years, he taught himself to play the guitar and began <span>exploring the musical gamut</span>: from jazz trios to progressive metal, and flamenco legends to indie folk. ",
                    section2: "At twenty-one, he formed a <span>pop band</span> in Delft, where he studied <span>computer science</span> by day and wrote and performed original songs by night. After graduation, the band fell apart, but Bas did not stop making music. The classical piano called him <span>back to his roots</span>, and he wrote two compositions on a digital piano in his cramped Amsterdam apartment. Juggling a full-time consulting job, a social life, and an expanding family, these were the only works he composed for several years.",
                    section3: "Craving more space and nature, he finally settled in a small village in the center of the Netherlands. Synchronistically, a <span>1978 Steinway</span> Model O came into his possession and his <span>long-time dream</span> — to compose and record a full-length album — was <span>reignited</span>, and then <span>catalysed</span> by the covid pandemic. At home more, Bas found more time to play and eventually recorded Abracadabra on this living room grand, like it was meant to be.",
                    section4: "With this debut album recently released, Bas is now tinkering with <span>new ideas</span> for a sophomore album and preparing the publishing of the Abracadabra <span>sheet music</span>. Plans for future <span>live performances</span> are also in the works."
                }
            },
            media: {
                title: "Media",
                sections: {
                    section1: "The album is not yet publicly released and can therefore not be listened to in its entirety. However, the album can be listened to on invitation via a private link (which might be in your email).",
                    section2: "In addition you could watch the video above/at the side in which I explain how the <span>magical musical cryptogram</span> came about.",
                    section3: "Download press kit (bio + high-res photos, 10MB)"
                }
            },
            checkout: {
                title: "Checkout",
                name: "Name",
                givenName: "Given name",
                familyName: "Family name",
                email: "Email",
                address: "Address",
                streetAndNumber: "Street + number",
                postalCode: "Postal code",
                city: "City",
                country: "Country",
                region: "Region / Province",
                contact: "Contact details",
                shipping: "Shipping details",
                sheetmusic: "Sheet music personalization",
                personalized: "Who is the sheet music for?",
                extra: "Extra",
                optional: "optional",
                remarks: "Additional remarks",
                processing: "Processing",
                pay: "Place order",
                orderdetails: "Order details",
                payment: "Payment (if required)",
                not_provided: "Please provide a valid value",
                email_not_valid: "The provided value is not a valid email address",
                country_not_valid: "The provided value is not a valid country code"
            },
            shop: {
                title: "Shop",
                showDetails: "show details",
                hideDetails: "hide details",
                shipping: "Shipment takes place within three working days via PostNL"
            },
            thankyou: {
                title: "Thank you!",
                yourorder: "Your order with number ",
                successful: " has been successfully completed.",
                willbeshipped: "Your order will be shipped as soon as possible.",
                redirected: "You will be redirected to the shop in 5 seconds"
            },
            cart: {
                title: "Cart",
                coupon: "Coupon",
                getcoupon: "No coupon? Follow and DM me on <a href='https://instagram.com/basbeelenpiano'>Instagram</a> or <a href='https://facebook.com/basbeelenmusic'>Facebook</a> to find out if there's one active right now.",
                haveacoupon: "Do you have a coupon?",
                redeem: "Redeem",
                freeorigami: "€5,- discount on a copy of 'Origami'",
                empty: "Your cart is empty",
                shipping: "Shipping",
                nl: "NL",
                international: "International",
                subtotal: "Subtotal",
                total: "Total",
                freeshipping: "Free shipping from",
                continue: "Shop",
                checkout: "Checkout",
                showcart: "Cart"
            },
            photos: {
                title: "Photos"
            },
            news: {
                title: "News",
                newsletter: "Interested in my personal, occasional newsletter?",
                placeholder: "your{'@'}emailaddress.com",
                subscribe: "Subscribe"
            },
            live: {
                title: "Live",
                nogigs: "There are currently no events planned",
                bookgig: "Book me!",
                futuregigs: "Upcoming events",
                pastgigs: "Past events"
            },
            contact: {
                title: "Contact",
                compositions: "Compositions and performance by",
                inqueries: "All inqueries",
                recording: "Recording and mixing by",
                mastering: "Mastering by",
                artwork: "Artwork by"
            },
            invite: {
                invite: "Invitation!",
                reason1: "Instead of my birthday I'd like to celebrate something else this year. I'm wrapping up a 2,5-year-long project as I'm finally releasing my debut album with eleven compositions for solo piano. I'm organizing a cultural evening to celebrate this accomplishment!",
                reason2: "It is going to be a unique event, especially if you will be there too!",
                location: "Location",
                programme: "Programme",
                programma1: "Doors open",
                programma2: "Music presentation",
                programma3: "Drinks in foyer",
                transportation: "Transportation",
                car: "Car",
                carline1: "There are no parking spots available at the location itself.",
                carline2: "You can park your car in the area around the building.",
                carline3: "There is an AH within a two-minute walk with a parking space where you can park for two hours with a parking disc. This is mainly intended for throughput during opening hours (until 22:00). When I inquired at the store, I was told that in practice there is no enforcement of the parking duration. So that seems like a good option, of course I can't give a 100% guarantee, but I would dare to do it myself :-)",
                publictransport: "Public transport",
                ovline1: "From train station Driebergen/Zeist it's ± 13 minutes by bus or OV-fiets",
                wishes: "Wishes",
                wish1: "A packed room is my biggest wish!",
                wish2: "A small gesture of appreciation at the end of the evening is always welcome to continue this musical journey."
            }
        },
        nl: {
            title: "Bas Beelen",
            invalid_email: "Het ingevulde emailadres is ongeldig",
            min_price_not_met: "De prijs van een product is lager dan de minimumprijs.",
            menu: {
                epk: "digitale perskit",
                album: "ALBUM",
                news: "NIEUWS",
                bio: "OVER BAS",
                media: "MEDIA",
                photos: "FOTO'S",
                shop: "WINKEL",
                live: "LIVE",
                contact: "CONTACT",
                subsubtitle: "componist & pianist",
                moreNews: "toon meer",
                lessNews: "toon minder"
            },
            privacy: {
                policy: "Privacyverklaring"
            },
            weekdays: {
                0: "Zondag",
                1: "Maandag",
                2: "Dinsdag",
                3: "Woensdag",
                4: "Donderdag",
                5: "Vrijdag",
                6: "Zaterdag"
            },
            months: {
                0: "januari",
                1: "februari",
                2: "maart",
                3: "april",
                4: "mei",
                5: "juni",
                6: "juli",
                7: "augustus",
                8: "september",
                9: "oktober",
                10: "november",
                11: "december"
            },
            album: {
                title: "Debuutalbum",
                getacopy: "Bepaal je prijs!",
                sheetmusic: "bladmuziek",
                sections: {
                    section1: "Een jaar lang thuis, een ontwaakte waardering voor de piano en een dosis opgespaarde creativiteit inspireren tot het ontstaan van Bas Beelens debuutalbum <span>Abracadabra</span>. De naam komt voort uit het hoofdmotief van de titeltrack, dat in een magisch <span>muzikaal cryptogram</span> deze kreet daadwerkelijk spelt. Met aanstekelijke melodieën en verrassende passages tovert Beelen een speels verhaal tevoorschijn, verteld aan de hand van elf solopianostukken.",
                    section2: "Het gebruik van <span>Romantische dynamiek</span> en <span>kleurrijke harmonieën</span> hinten respectievelijk aan Rachmaninoff en Debussy als belangrijke inspiratiebron. Echter, de aanwezigheid van popliedstructuren en <span>catchy melodieën</span> in onregelmatige maatsoorten onthullen net zo goed <span>hedendaagse invloeden</span>, zoals Sufjan Stevens, Patrick Watson en het Avishai Cohen Trio.",
                    section3: "Het resultaat is een <span>caleidoscoop van geluid</span>, een eclectische mix van klassieke muziek met indie subgenres en een vleugje jazz. Beelen werpt zijn <span>eigen unieke licht</span> op dit veelzijdige instrument en laat horen dat de piano je kan verbazen, je op kan vrolijken en je nieuwe energie kan bezorgen. Luister en laat je betoveren. <span>Abracadabra!</span>",
                    section4: ""
                }
            },
            bio: {
                title: "Over Bas",
                sections: {
                    section1: "Overdag werkzaam als <span>data engineer</span> transformeert hij 's avonds in een gegadigd <span>componist</span>. Bas Beelen nam zijn eerste lessen klassiek piano als zevenjarige samen met zijn vader. In het begin aangemoedigd met <span>dropjes</span> om te blijven oefenen, ontwikkelde hij uiteindelijk intrinsieke motivatie om te spelen en een liefde voor muziek in het algemeen. In zijn tienerjaren leert hij zichzelf gitaar spelen en begint hij met het <span>verkennen van het muzikale spectrum</span>: van jazz trios tot progressieve metal, van flamenco legendes tot indie folk.",
                    section2: "Op eenentwintigjarige leeftijd start hij een <span>popband</span> in Delft, waar hij overdag <span>computer science</span> studeert en 's avonds het liefst originele liedjes schrijft en ten gehore brengt. Aan het eind van de studie valt de band uiteen, maar Bas stopt niet met het maken van muziek. De klassieke piano roept hem <span>terug naar zijn roots</span>, en hij schrijft twee composities op zijn digitale piano in zijn krappe Amsterdamse appartement. Het zijn de enige werken die hij in een lange periode componeert, terwijl hij zijn tijd verdeelt over een full-time consultancy baan en een zich uitbreidende familie.",
                    section3: "Met behoefte aan meer ruimte en natuur, strijkt hij met zijn gezin neer in een dorpje op de Utrechtse Heuvelrug. Precies op dat moment komt een <span>Steinway Model O</span> uit 1978 op zijn pad en komt zijn <span>langgekoesterde droom</span> — het componeren en opnemen van een volledig album — weer tot leven en wordt vervolgens gekatalyseerd door de covid-pandemie. Werkend vanuit huis, lukt het Bas om ook meer tijd achter de piano te zitten wat uiteindelijk leidt tot de opname van <span>Abracadabra</span>; thuis, op zijn <i>living room grand</i>. Alsof het zo had moeten zijn!",
                    section4: "Nu zijn debuutalbum onlangs is uitgebracht, is Bas alweer bezig met <span>nieuwe ideëen</span> voor een opvolger en richt hij zich op het uitgeven van de <span>bladmuziek</span> van Abracadabra. Plannen voor toekomstige <span>optredens</span> liggen ook op de plank.",
                }
            },
            media: {
                title: "Media",
                sections: {
                    section1: "Het album is nog niet publiekelijk gereleased en kan hier dus ook nog niet beluisterd worden. Wél is het mogelijk om het album op uitnodiging via een private link te beluisteren (zie email).",
                    section2: "Bekijk als aanvulling de video hiernaast waarin ik uitleg hoe het <span>magische muzikale cryptogram</span> tot stand is gekomen.",
                    section3: "Download perskit (bio + hoge resolutie foto's, 10MB)"
                }
            },
            thankyou: {
                title: "Bedankt!",
                yourorder: "Jouw bestelling met bestelnummer ",
                successful: " is succesvol afgerond.",
                willbeshipped: "Je bestelling wordt zo spoedig mogelijk naar je verzonden.",
                redirected: "Je wordt in 5 seconden doorgestuurd naar de webshop."
            },
            checkout: {
                title: "Bestellen",
                name: "Naam",
                givenName: "Voornaam",
                familyName: "Achternaam",
                email: "Email",
                address: "Adres",
                streetAndNumber: "Straatnaam + huisnummer",
                region: "Regio / Provincie",
                postalCode: "Postcode",
                city: "Woonplaats",
                country: "Land",
                contact: "Contactgegevens",
                shipping: "Verzendgegevens",
                sheetmusic: "Bladmuziek personalisatie",
                personalized: "Voor wie is de bladmuziek?",
                optional: "optioneel",
                extra: "Extra",
                remarks: "Aanvullende opmerkingen",
                processing: "Verwerken",
                pay: "Bestelling plaatsen",
                orderdetails: "Aankoopgegevens",
                payment: "Betaling (indien nodig)",
                not_provided: "Vul een geldige waarde in",
                email_not_valid: "Het opgegeven emailadres is niet valide",
                country_not_valid: "De opgegeven landcode is niet valide"
            },
            shop: {
                title: "Winkel",
                showDetails: "toon details",
                hideDetails: "verberg details",
                shipping: "Verzending binnen drie werkdagen via PostNL"
            },
            cart: {
                title: "Mandje",
                coupon: "Kortingscode",
                getcoupon: "Geen code? Volg en DM me op <a href='https://instagram.com/basbeelenpiano'>Instagram</a> of <a href='https://facebook.com/basbeelenmusic'>Facebook</a> om te horen of er één actueel is.",
                haveacoupon: "Heb je een kortingscode?",
                redeem: "Verzilver",
                freeorigami: "€5,- korting op een exemplaar van 'Origami'",
                shipping: "Verzendkosten",
                nl: "NL",
                international: "Internationaal",
                empty: "Je mandje is leeg",
                subtotal: "Subtotaal",
                total: "Totaal",
                freeshipping: "Gratis verzending vanaf",
                continue: "Winkel verder",
                checkout: "Bestellen",
                showcart: "Mandje",
            },
            photos: {
                title: "Foto's"
            },
            news: {
                title: "Nieuws",
                newsletter: "Geïnteresseerd in mijn persoonlijke nieuwsbrief?",
                placeholder: "jouw{'@'}emailadres.nl",
                subscribe: "Schrijf in"
            },
            live: {
                title: "Live",
                nogigs: "Er zijn momenteel geen optredens gepland",
                bookgig: "Boek me!",
                futuregigs: "Verwachte optredens",
                pastgigs: "Eerdere optredens"
            },
            contact: {
                title: "Contact",
                inqueries: "Vragen",
                compositions: "Composities en uitvoering door",
                recording: "Recording en mixing door",
                mastering: "Mastering door",
                artwork: "Artwork door"
            },
            invite: {
                invite: "Uitnodiging!",
                reason1: "In plaats van mijn verjaardag vier ik dit jaar iets anders. Na een project van 2,5 jaar breng ik mijn debuutalbum met elf piano composities de wijde wereld in. Met het organiseren van een culturele avond sta ik daar graag een moment bij stil!",
                reason2: "Het wordt een unieke ervaring, helemaal als jij/jullie erbij bent/zijn!",
                location: "Locatie",
                programme: "Programma",
                programma1: "Inloop",
                programma2: "Muziek presentatie",
                programma3: "Borrel in foyer",
                transportation: "Vervoer",
                car: "Auto",
                carline1: "Er zijn geen parkeerplekken beschikbaar bij de locatie zelf.",
                carline2: "Parkeren kan in de straten rondom.",
                carline3: "Op twee minuten loopafstand is een AH met een parkeerplaats waar je met parkeerschijf twee uur mag staan. Dat is met name bedoeld voor doorstroom tijdens openingstijden (tot 22:00). Bij navraag in de winkel kreeg ik te horen dat er in de praktijk sowieso geen handhaving van de parkeerduur is. Dus dat lijkt een goede optie, uiteraard kan ik geen 100% garantie geven maar zou het zelf wel aandurven :-)",
                publictransport: "Openbaar vervoer",
                ovline1: "Vanaf station Driebergen/Zeist is het een klein kwartier met bus of OV fiets.",
                wishes: "Wensen",
                wish1: "Een volle zaal is mijn grootste wens!",
                wish2: "Een kleine waardering aan het eind van de avond is altijd welkom om de uit de hand gelopen hobby verder te brengen."
            }
        }
    }
})

store.subscribe((mutation, state) => {
    // Store the state object as a JSON string
    localStorage.setItem('store', JSON.stringify(state));
});

const app = createApp(App).use(router).use(i18n).use(VueCryptojs).use(store).use(VueViewer).use(VueGtag, {
    appName: 'basbeelen.com',
    pageTrackerScreenviewEnabled: true,
    config: {
        id: process.env.VUE_APP_GTAG,
    },
}, router).use(easySpinner, {
    /*options*/
    prefix: 'easy',
})

app.config.globalProperties.$analytics = analytics;

app.mount('#app');