<template>
  <div class="row">
    <div class="col-6 backcover left-side">
      <img src="../assets/backcover-1400x1400.jpg" />
    </div>
    <div class="col-6 contact" id="contact">
      <h1>{{ t("contact.title") }}</h1>
      <div class="social">
        <a
          target="_blank"
          href="https://open.spotify.com/artist/03oCpUsf0gx9g2cO1MJeCT"
        >
          <img class="logo" src="../assets/logo_spotify.png" />
        </a>
        <!-- <a target="_blank" href="https://www.facebook.com/basjebeelen"
          ><img class="logo" src="../assets/logo_facebook.png"
        /></a> -->
        <a target="_blank" href="https://www.instagram.com/basbeelenpiano"
          ><img class="logo" src="../assets/logo_insta.png"
        /></a>
        <a target="_blank" href="https://www.youtube.com/@basbeelenpiano"
          ><img class="logo" src="../assets/logo_youtube.png"
        /></a>
      </div>
      <p>
        <!-- <span>{{ t("contact.inqueries") }}</span
        ><br /> -->
        Bas Beelen<br />E:
        <a href="mailto:mail@basbeelen.com">mail@basbeelen.com</a><br />T: +31 6
        303 65 962
      </p>

      <p>
        <span>Abracadabra credits</span><br />
        {{ t("contact.compositions") }}
        <a target="_blank" href="https://www.basbeelen.com">Bas Beelen</a><br />
        {{ t("contact.recording") }}
        <a target="_blank" href="https://www.studiojoneski.com/">Sam Jones</a
        ><br />
        {{ t("contact.mastering") }}
        <a target="_blank" href="https://www.wesseloltheten.nl"
          >Wessel Oltheten</a
        ><br />
        {{ t("contact.artwork") }}
        <a target="_blank" href="https://geertjebrandenburg.nl"
          >Geertje Brandenburg</a
        ><br />
      </p>
      <!-- <p>
        <span>Voorwaarden</span><br />
        <a href="/voorwaarden" target="_blank">Algemene voorwaarden</a><br />
        <a href="/returns" target="_blank">Retourneren</a><br />
        <a href="/privacy" target="_blank">{{ t("privacy.policy") }}</a>
      </p> -->
      <p>
        <a href="/voorwaarden" target="_blank">Algemene voorwaarden</a><br />
        <a href="/returns" target="_blank">Retourneren</a><br />
        <a href="/privacy" target="_blank">{{ t("privacy.policy") }}</a>
      </p>
      <div class="footer">
        Copyright &copy; {{ new Date().getFullYear() }} Bas Beelen
      </div>
    </div>
  </div>
</template>

<script>
import { useI18n } from "vue-i18n";
export default {
  name: "Contact",
  setup() {
    return useI18n();
  },
};
</script>

<style scoped>
.social {
  padding-left: 25px;
  /* margin-left: 25px; */
}
.logo {
  filter: alpha(opacity=65);
  opacity: 0.65;
  width: 36px;
  margin-right: 15px;
}

.logo:hover {
  filter: alpha(opacity=100);
  opacity: 1;
}
.footer {
  padding: 20px;
  padding-top: 20px;
  font-family: "STIX Two Math", "Lato", "Merriweather", "Truculenta", "Lekton",
    "Puritan", "Neutral", "Helvetica Neue";
  font-size: 13px;
  width: 100%;
  text-align: center;
  font-style: italic;
}
.footer a {
  color: darkblue;
  margin-left: 20px;
  margin-right: 20px;
  font-style: none;
  font-size: 8px;
}

@media only screen and (min-width: 900px) {
  .contact p {
    text-align: left;
  }
  .backcover {
    background-image: url("../assets/backcover-1400x1400.jpg");
    background-position: calc((100vw - 200vh) / 2);
    /* background-position: calc(100% + ((50vw - 100%) / 2)); */
    /* background-size: max(50vw, 100vh); */
  }
  .social {
    text-align: left;
    padding-left: min(5vw, 8vh);
    /* margin-right: 12vw; */
  }
  .logo:first-child {
    display: unset;
    width: 50px;
  }
  .social a:not(:first-child) {
    padding-left: 40px;
  }
  /* .logo:not(:first-child) {
    display: none;
  } */
}
</style>
