<template>
  <div>
    <h2>Retourneren</h2>

    Producten die voldoen aan de
    <router-link :to="{ path: '/voorwaarden' }">voorwaarden</router-link> om
    geretourneerd te worden, kunnen binnen 14 dagen worden teruggestuurd.

    <br /><br />

    Laat eerst per <a href="mailto:mail@basbeelen.com">email</a> weten dat je de
    bestelling (deels) wil terugsturen. Binnen 3 werkdagen krijg je een
    bevestiging of (een gedeelte van) de bestelling inderdaad in aanmerking komt
    voor retour. Verpak vervolgens de bestelling goed in en stuur op.

    Neem in geval van klachten, ook na de 14 dagen, altijd contact op zodat we
    samen kunnen kijken of er een oplossing te vinden is.
  </div>
</template>

<style scoped>
h2 {
  font-family: Helvetica Neue;
  color: #000000;
  background-image: #000000;
  -webkit-text-fill-color: unset;
  -webkit-background-clip: unset;
}
div {
  width: 700px;
  font-family: Helvetica neue;
  margin-left: 50px;
}
</style>
