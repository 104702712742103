<template>
  <div class="row">
    <div class="col-6 media">
      <LiteYouTubeEmbed
        title="A Magical Musical Cryptogram explained"
        id="iekyEeLqlYg"
        poster="maxresdefault"
        params="cc_load_policy=1&cc_lang_pref={{ this.lang }}"
      />
    </div>
    <div class="col-6" id="media">
      <h1>{{ t("media.title") }}</h1>
      <iframe
        class="spotifyplayer"
        src="https://open.spotify.com/embed/playlist/4SRRFaNhsniwqUCT8MMqzt?utm_source=generator"
        width="50%"
        height="200px"
        frameBorder="0"
        allowfullscreen=""
        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
      ></iframe>
      <p v-html="t('media.sections.section2')"></p>
      <p>
        <router-link
          :to="{ name: 'epk', query: { org: 'site' } }"
          target="_blank"
          v-html="t('media.sections.section3')"
        ></router-link>
      </p>
    </div>
  </div>
  <!-- <div class="row">
    <div class="col-6 media">
      <LiteYouTubeEmbed
        title="'Ik ben de ochtend' live at the Abracadabra release show"
        id="CzzXVSvH6h4"
        poster="maxresdefault"
      />
    </div>
    <div class="col-6 media">
      <LiteYouTubeEmbed
        title="'Waas' live at the Abracadabra release show"
        id="NYLxsHF_W_0"
        poster="maxresdefault"
      />
    </div>
  </div> -->
</template>

<script>
import { useI18n } from "vue-i18n";
import LiteYouTubeEmbed from "vue-lite-youtube-embed";
export default {
  name: "Media",
  setup() {
    return useI18n();
  },
  components: {
    LiteYouTubeEmbed,
  },
};
</script>

<style>
.yt-lite {
  height: 100%;
}
</style>
<style lang="css" scoped>
.media .lyt-activated {
  top: 50%;
  margin-top: -28%;
  height: unset;
}
.spotifyplayer {
  border-radius: 12px;
  width: 80%;
  margin-left: 10%;
  margin-right: 10%;
}
</style>